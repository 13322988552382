<template>
  <b-row class="mt-5">
    <b-col
        v-for="(chart, chartIndex) in productChart"
        :key="chartIndex"
        cols="12"
        lg="6"
    >
      <b-card>
        <div class="w-full  bg-white">
          <div class=" mb-[15px] block lg:flex">
            <div class="w-full flex gap-3" :class="{'lg:w-[60%]': chart.mode === 'SALES'}">
              <div class="w-[5%]">
                <div
                    v-if="chart.selectOptions"
                    class=""
                >
                  <b-navbar-nav class="nav align-items-center">
                    <b-nav-item-dropdown
                        no-caret
                        id="dropdown-grouped"
                        variant="link"
                        class="dropdown-language p-0"
                    >
                      <template class="p-0" #button-content>
                        <img src="@/assets/icon/chevronDown.png" alt="dropdown" class="w-[15px]"/>
                      </template>

                      <b-dropdown-item
                          v-for="(label, index) in chart.allOptions"
                          :key="index"
                          @click="switchOption(chartIndex, label, chart.extra)"
                      >
                        <span class="ml-50" v-text="$t(label.label)"/>
                      </b-dropdown-item>
                    </b-nav-item-dropdown>
                  </b-navbar-nav>
                </div>
              </div>
              <div class="w-[95%]">
                <p
                    v-if="chart.selectOptions"
                    class="mb-0 text-[15px] font-semibold"
                    v-text="
                          $t(chart.cardTitle, {
                            option: $t(chart.chooseOption.label),
                          })
                        "
                />
                <p
                    v-else
                    class="mb-0 text-[15px] font-semibold"
                    v-text="
                          $t(chart.cardTitle, {
                            option: chart.optionText ? chart.optionText : '',
                          })
                        "
                />
              </div>
            </div>
            <div class="w-full pt-[10px] lg:pt-[0px] lg:w-[40%]"
                 v-if="chart.hasOwnProperty('chooseOption') && chart.mode === 'SALES'">
              <div class="h-full">
                <div v-if="chart.hasOwnProperty('chooseOption') && chart.mode === 'SALES'"
                     class="flex justify-end">
                  <v-select v-if="chart.chooseOption.value === 'article'"
                            :placeholder="$t('StatisticsCard.productTab.article')"
                            :options="articleList"
                            v-model="customerData.article"
                            @search="searchArticle"
                            @option:selected="setArticle($event)"
                            class="resource-selector customer-chart w-full h-[30px]"
                            label="description"
                  />
                  <v-select v-if="chart.chooseOption.value === 'segment'"
                            :placeholder="$t('StatisticsCard.customerTab.segment')"
                            :options="segmentList"
                            v-model="customerData.segment"
                            @search="searchSegment"
                            @option:selected="setSegment($event)"
                            label="name"
                            class="resource-selector customer-chart w-full h-[30px]"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="w-full py-[10px] flex">
            <div class="w-[90%] flex justify-center">
              <div
                  v-if="chart.hasOwnProperty('tabs')"
                  class="bg-secondaryColor d-flex bigGap align-items-center rounded-[5px] px-[10px] py-[6px]"
                  :style="
                        chart.tabs && chart.tabs.length < 3
                          ? ''
                          : ''
                      "
              >
                      <span v-for="(data, index) in chart.tabs" :key="index">
                        <p
                            v-if="data.active"
                            class="bg-[#f6f6f6] mb-[0px] border-[1px] px-[15px] rounded-[2px] border-[#f6f6f6]"
                            v-text="$t(data.label)"
                        />

                        <p
                            v-else
                            @click="switchTabs(chartIndex, index, chart.extra)"
                            class="mb-0 text-light px-[15px]"
                            style="font-size: 15px"
                            role="button"
                            v-text="$t(data.label)"
                        />
                      </span>
              </div>
            </div>
            <div class="w-[10%] flex justify-end gap-3">
              <span
                  v-b-tooltip="chart.info ? chart.info[messageInfo] : ''"
              >
                      <mdicon
                          role="button"
                          :width="20"
                          :height="20"
                          name="help-circle-outline"
                      />
                    </span>
              <!--              <small @click="openModalForArticles(chart.mode)" v-if="chart.searchArticle"-->
              <!--                     class="cursor-pointer text-theme-sidebar font-semibold pt-[2px]">Sök</small>-->
            </div>
          </div>
        </div>

        <b-card-text>
          <apexchart
              :type="chart.type"
              :options="chart.options"
              :series="chart.series"
              height="300px"
          />
        </b-card-text>
      </b-card>
    </b-col>

    <CustomNoHeaderModalVue v-if="openModal" width="w-1/2 md:w-1/4" :title="''" @handleClose="openModal = false"
                            :close="!true">
      <div class="flex flex-col" slot="header">
        <div class="flex flex-col md:flex-row md:justify-between md:items-center gap-5 border-b p-1">
          <div class="flex flex-col">
            <h2 class="text-xl md:text-2xl font-semibold font-poppins text-gray-900">Sök</h2>
          </div>
        </div>
      </div>
      <div class="flex flex-col" slot="body">
        <div class="mb-1">
          <div class="flex flex-col h-60 mt-2">
            <div v-if="articleSearch">
              <b-form-group>
                <b-form-radio
                    v-model="searchArticleForm.radio"
                    name="radio-1"
                    value="ARTICLE">
                  <span>Articles</span>
                </b-form-radio>
                <b-form-radio
                    name="radio-1"
                    v-model="searchArticleForm.radio"
                    value="ARTICLE_SEGMENT">
                  <span>Article Segment</span>
                </b-form-radio>
              </b-form-group>
              <b-form-group>
                <v-select class="w-full" @option:selected="setData($event)" :filterable="false"
                          :options="articleResults" @search="searchData">
                  <template slot="no-options">
                    Sök...
                  </template>
                  <template slot="option" slot-scope="option">
                    <div class="d-center">
                      {{ option.label }}
                    </div>
                  </template>
                  <template slot="selected-option" slot-scope="option">
                    <div class="selected d-center">
                      {{ option.label }}
                    </div>
                  </template>
                </v-select>
              </b-form-group>
              <div class="flex">
                <button @click="performGraphSearch" :disabled="processing" type="submit"
                        class="flex w-1/2 c-p-2 text-center focus:outline-none bg-theme-teal text-white whitespace-nowrap rounded-md">
                                    <span class="w-full text-center" v-if="processing">
                                        <b-spinner
                                            small
                                            type="grow"
                                        />
                                        {{ $t('Message.loading') }}...
                                    </span>

                  <span
                      class="whitespace-nowrap text-center w-full text-base"
                      v-else
                      v-text="
                                            'Sök'
                                        "
                  />
                </button>
              </div>

            </div>
          </div>
        </div>
      </div>
    </CustomNoHeaderModalVue>
  </b-row>
</template>

<script>
import CustomNoHeaderModalVue from '@/@core/customComponent/CustomNoHeaderModal.vue';
import {dummyChartMixins} from './dummyChartMixins';
import debounce from 'lodash.debounce'
import vSelect from 'vue-select';

export default {
  mixins: [dummyChartMixins],
  components: {vSelect, CustomNoHeaderModalVue},
  name: 'products',
  data() {
    return {
      chartType: ['area', 'bar', 'bar', 'polarArea'],
      tabs: [
        {
          label: 'Incl.',
          active: true,
        },
        {
          label: 'Excl.',
          active: false,
        },
      ],
      articleResults: [],
      openModal: false,
      searchArticleForm: {
        value: '',
        radio: 'ARTICLE'
      },
      processing: false,
      searchArticlePerform: false,
      customerData: {
        article: '',
        segment: '',
      },
      articleSalesData: {},
      articleInfo: {},
      articleList: [],
      segmentList: [],
      highestArticleList: [],
      benefitCostRatio: [],
    };
  },
  watch: {
    'searchArticleForm.radio': function (n, o) {
      if (n) {
        this.articleResults = []
      }
    }
  },
  methods: {
    performGraphSearch() {
      this.processing = true
      const payload = {}
      let mode = ''
      if (this.searchArticleForm.radio === 'ARTICLE') {
        mode = 'ARTICLE'
        payload['article_number'] = this.searchArticleForm.value
      } else {
        mode = 'SEGMENT'
        payload['segment_id'] = this.searchArticleForm.value
      }
      this.getArticleSales(payload, mode)

    },
    searchData(search, loading) {
      if (search.length) {
        loading(true);
        this.searchRemoteData(loading, search, this);
      }
    },
    searchRemoteData: debounce(function (loading, search, vm) {
      const URL = this.searchArticleForm.radio === 'ARTICLE' ? 'articles?search=' + escape(search) : 'segments/articles?search=' + escape(search)

      this.$useJwt.useBaseURLOnly(URL)
          .then(res => {
            if (this.searchArticleForm.radio === 'ARTICLE') {
              this.articleResults = res.data.data.map(el => {
                return {label: el.article_number, value: el.article_number}
              })
            } else {
              this.articleResults = res.data.results.map(el => {
                return {label: el.name, value: el.id}
              })
            }
            loading(false);
          })
          .catch(err => {
          })
    }, 350),
    setData(item) {
      this.searchArticleForm.value = item.value
    },
    openModalForArticles(data) {
      this.articleSearch = true
      this.openModal = true
    },
    switchTabs(chartIndex, index, extra = null) {
      let curentTabs = this.productChart[chartIndex].tabs;
      let targetChart = this.productChart[chartIndex]
      curentTabs.find(item => item.active === true).active = false;
      curentTabs[index].active = true;
      if (targetChart.mode === "SALES") {
        this.switchSales(targetChart.chooseOption.value, curentTabs[index].salesAmount)
      } else {
        this.reconstructGraph(chartIndex, null, extra)
      }


    },

    switchOption(chartIndex, value, extra) {
      this.productChart[chartIndex].chooseOption = value;

      this.reconstructGraph(chartIndex, value.value, extra)
    },

    reconstructGraph(chartIndex, value = null, extra = false) {
      if (extra) {
        this.renderExtraGraph(chartIndex, value, extra)
      } else {
        if (this.productChart[chartIndex].type === 'polarArea') {
          let currentTabs = this.productChart[chartIndex].tabs.find(item => item.active === true);

          let currentData = value ?
              this.productChart[chartIndex][value] : this.productChart[chartIndex][this.productChart[chartIndex].chooseOption.value]

          this.productChart[chartIndex].series = currentData.map(item => {
            return item[currentTabs.productPrice]
          }),

              this.productChart[chartIndex].options = {
                labels: currentData.map(item => {
                  return `${item.article_number}`;
                }),
              };

        } else {
          if (this.productChart[chartIndex].tabs) {
            let currentTabs = this.productChart[chartIndex].tabs.find(item => item.active == true);

            let currentData = value ?
                this.productChart[chartIndex][value] : this.productChart[chartIndex][this.productChart[chartIndex].chooseOption.value]

            this.productChart[chartIndex].series = [
              {
                name: this.$t(
                    this.productChart[chartIndex].chartLabel
                ),
                data: currentData.map(item => {
                  return item[currentTabs.productPrice];
                }),
              },
            ];
            // Artikelnr.
            this.productChart[chartIndex].options = {
              xaxis: {
                categories: currentData.map(item => {
                  return `${item.article_number}`;
                }),
              },
            };
          }
          // else {
          //     let currentData = this.productChart[chartIndex][value]

          //     this.productChart[chartIndex].series = [
          //         {
          //             name: this.$t(
          //                 this.productChart[chartIndex].chartLabel
          //             ),
          //             data: currentData.map(item => {
          //                 return item[this.productChart[chartIndex].lookup];
          //             }),
          //         },
          //     ];

          //     this.productChart[chartIndex].options = {
          //         xaxis: {
          //             categories: currentData.map(item => {
          //                 return `${item.customer_number}  ${item.customer_name}`;
          //             }),
          //         },
          //     };
          // }
        }
      }
    },

    renderExtraGraph(chartIndex, value, extra) {
      if (this.productChart[chartIndex].tabs) {
        let currentTabs = this.productChart[chartIndex].tabs.find(item => item.active == true);

        let currentData = null

        if (this.productChart[chartIndex].searchArticle && !this.searchArticlePerform) {
          return
        }

        if (this.productChart[chartIndex].chooseOption) {
          currentData = value ?
              this.productChart[chartIndex][value] : this.productChart[chartIndex][this.productChart[chartIndex].chooseOption.value]
        } else {
          currentData = this.productChart[chartIndex]['full']
        }

        if (Object.keys(currentData).length > 0) {
          Object.keys(currentData).map(el => {
            let objectData = currentData[el]
            Object.keys(objectData).map(row => {

              this.productChart[chartIndex].options = {
                xaxis: {
                  categories: Object.keys(objectData[row]).map(item => {
                    return `${item}`;
                  }),
                },
              };

              const obj = {}
              obj.name = this.$t(
                  this.productChart[chartIndex].chartLabel
              )
              obj.data = Object.keys(objectData[row]).map(item => {
                return `${objectData[row][item][currentTabs.salesAmount]}`;
              })
              this.productChart[chartIndex].series.push(obj)
            })
          })
        }

      }
    },


    async getArticleHighest() {
      const chartIndex = 0
      const {data} = await this.articleHighestLowest()
      const arrayData = data.data
      const info = {swe_info: data.swe_info, eng_info: data.eng_info}

      const chartOption =
          this.productChart[chartIndex].chooseOption.value;
      const vatActive = this.productChart[chartIndex].tabs.find(
          tab => tab.active == true
      );

      Object.keys(arrayData).forEach(item => {
        this.productChart[chartIndex][item] = arrayData[item];

      });


      let currentData =
          this.productChart[chartIndex][chartOption];
      this.highestArticleList = currentData
      this.productChart[chartIndex].series = [
        {
          name: this.$t('statistics.article'),
          data: currentData.map(item => {
            return item[vatActive.productPrice]
          }),
        },
      ];

      this.productChart[chartIndex].options = {
        xaxis: {
          categories: currentData.map(item => {
            return `${item.article_number}`;
          }),
        },
      };
      this.productChart[chartIndex].info = info
    },

    async getArticleSales(searchData, optionTxt) {
      const chartIndex = 1
      const {data} = await this.articleHistoricSales(searchData)
      const arrayData = data.data
      const info = {swe_info: data.swe_info, eng_info: data.eng_info}
      this.processing = false
      this.openModal = false

      const vatActive = this.productChart[chartIndex].tabs.find(
          tab => tab.active == true
      );

      // Object.keys(arrayData).forEach(item => {
      //     this.productChart[chartIndex][item] = arrayData[item];

      // });

      this.productChart[chartIndex].optionText = optionTxt === 'ARTICLE' ? "for `Article`" : "for `Article Segment`"
      this.productChart[chartIndex]['full'] = arrayData
      let currentData = arrayData

      this.productChart[chartIndex].series = []
      let searchKey = null

      if (this.searchArticleForm.value === 'ARTICLE') {
        searchKey = 'article_number'
      } else {
        searchKey = 'segment_id'
      }

      if (Object.keys(currentData).length > 0) {
        Object.keys(currentData).map(el => {
          let objectData = currentData[el]
          Object.keys(objectData).map(row => {

            this.productChart[chartIndex].options = {
              xaxis: {
                categories: Object.keys(objectData[row]).map(item => {
                  return `${item}`;
                }),
              },
            };

            const obj = {}
            obj.name = this.$t(
                this.productChart[chartIndex].chartLabel
            )
            obj.data = Object.keys(objectData[row]).map(item => {
              return `${objectData[row][item][vatActive.salesAmount]}`;
            })
            this.productChart[chartIndex].series.push(obj)
          })
        })
      }

      this.productChart[chartIndex].extra = true
      this.productChart[chartIndex].unified = true
      this.productChart[chartIndex].info = info

      this.searchArticlePerform = true

      this.productChart[chartIndex].info = info
    },

    async getArticleMostLeast() {
      const chartIndex = 2
      const {data} = await this.articleMostLeastSold()
      const arrayData = data.data
      const info = {swe_info: data.swe_info, eng_info: data.eng_info}


      const chartOption =
          this.productChart[chartIndex].chooseOption.value;
      const vatActive = this.productChart[chartIndex].tabs.find(
          tab => tab.active == true
      );

      Object.keys(arrayData).forEach(item => {
        this.productChart[chartIndex][item] = arrayData[item];

      });


      let currentData =
          this.productChart[chartIndex][chartOption];


      this.productChart[chartIndex].series = currentData.map(item => {
        return item[vatActive.productPrice]
      }),

          this.productChart[chartIndex].options = {
            labels: currentData.map(item => {
              return `${item.article_number}`;
            }),
          };
      this.productChart[chartIndex].info = info

    },
    async getBenefitCost() {
      const chartIndex = 3
      const {data} = await this.articleBenefitRatio()
      const arrayData = data.data
      const info = {swe_info: data.swe_info, eng_info: data.eng_info}


      const chartOption =
          this.productChart[chartIndex].chooseOption.value;
      const vatActive = this.productChart[chartIndex].tabs.find(
          tab => tab.active === true
      );

      Object.keys(arrayData).forEach(item => {
        this.productChart[chartIndex][item] = arrayData[item];

      });


      let currentData =
          this.productChart[chartIndex][chartOption];
      this.benefitCostRatio = currentData
      this.productChart[chartIndex].series = [
        {
          name: this.$t('statistics.article'),
          data: currentData.map(item => {
            return item[vatActive.productPrice]
          }),
        },
      ];
      this.productChart[chartIndex].options = {
        xaxis: {
          categories: currentData.map(item => {
            return `${item.article_number}`;
          }),
        },
      };
      this.productChart[chartIndex].info = info
    },
    searchArticle(search, loading) {
      if (search.length) {
        loading(true);
        this.searchRemoteArticles(loading, search, this);

      }
    },
    searchRemoteArticles: debounce(function (loading, search, vm) {
      this.$useJwt.searchArticles(`?search=${escape(search)}`)
          .then((res) => {
            this.articleList = res.data.data;
            loading(false);
          })
          .catch((err) => {
          });
    }, 350),
    setArticle(item) {
      let params = `article_number=${item.article_number}`
      this.getArticleSalesChart(params, 'article')
    },
    searchSegment(search, loading) {
      if (search.length) {
        loading(true);
        this.searchArticleSegment(loading, search, this);

      }
    },
    searchArticleSegment: debounce(function (loading, search, vm) {
      this.$useJwt
          .customSegment(
              {URL: `/articles?search=${escape(search)}`, method: "get"}
          )
          .then((res) => {
            this.segmentList = res.data.results
            loading(false);
          });
    }, 350),
    setSegment(item) {
      let params = `segment_id=${item.id}`
      this.getArticleSalesChart(params, 'segment')
    },
    async getArticleSalesChart(params, section) {
      const {data} = await this.articleHistorySales(`${params}`);
      let chartIndex = this.productChart.findIndex(item => item.mode === 'SALES')
      if (chartIndex < 0) {
        return false
      }
      this.articleInfo = {swe_info: data.swe_info, eng_info: data.eng_info}
      this.articleSalesData = data.data
      this.productChart[chartIndex].xaxisLabel = 'Months'
      let currentTabs = this.productChart[chartIndex].tabs;
      let activeTab = currentTabs.filter(item => item.active === true)
      let tab = activeTab.length > 0 ? activeTab[0].salesAmount : 'total_invoices'
      this.switchSales(section, tab)
    },
    switchSales(section, value) {
      let cData = {}
      let salesSeries = []
      let dSeries = {}
      let chartIndex = this.productChart.findIndex(item => item.mode === 'SALES')
      if (section === 'article') {
        if (this.articleSalesData.hasOwnProperty('article_number') === false) {
          return
        }
        dSeries = this.articleSalesData['article_number'][this.customerData.article.article_number]
        this.productChart[chartIndex].options = {
          xaxis: {
            categories: Object.keys(dSeries),
          },
        };
      } else {
        if (this.articleSalesData.hasOwnProperty('segment_id') === false) {
          return
        }
        dSeries = this.articleSalesData['segment_id'][this.customerData.segment.id]
        this.productChart[chartIndex].options = {
          xaxis: {
            categories: Object.keys(dSeries),
          },
        };
      }
      Object.keys(dSeries).map(item => {
        cData[item] = dSeries[item]
        let total = dSeries[item].hasOwnProperty(value) === true ? dSeries[item][value] : 0
        salesSeries.push(total)
      })
      this.productChart[chartIndex].series = [
        {
          name: this.$t('statistics.article'),
          data: salesSeries,
        },
      ];
      this.productChart[chartIndex].info = this.articleInfo

    },

  },
  mounted() {
    this.getArticleHighest()
    // this.getArticleSales()
    this.getArticleMostLeast()
    this.getBenefitCost()
  }
};
</script>

<style>
.sales__input {
  position: absolute;
  right: -372px;
  top: -16px;
}

.select-width {
  width: 132px;
}
</style>
