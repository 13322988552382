<template>
  <b-row class="mt-5">
    <b-col
        v-for="(chart, chartIndex) in invoicesChart"
        :key="chartIndex"
        cols="12"
        lg="6"
    >
      <b-card>
        <div class="w-full  bg-white">
          <div class=" mb-[15px] block lg:flex">
            <div class="w-full flex gap-3" :class="{'lg:w-[60%]': chart.mode === 'SALES'}">
              <div class="w-[5%]" v-if="chart.selectOptions">
                <div
                    v-if="chart.selectOptions"
                    class=""
                >
                  <b-navbar-nav class="nav align-items-center">
                    <b-nav-item-dropdown
                        no-caret
                        id="dropdown-grouped"
                        variant="link"
                        class="dropdown-language p-0"
                    >
                      <template class="p-0" #button-content>
                        <img src="@/assets/icon/chevronDown.png" alt="dropdown" class="w-[15px]"/>
                      </template>

                      <b-dropdown-item
                          v-for="(label, index) in chart.allOptions"
                          :key="index"
                          @click="switchOption(chartIndex, label, chart.extra)"
                      >
                        <span class="ml-50" v-text="$t(label.label)"/>
                      </b-dropdown-item>
                    </b-nav-item-dropdown>
                  </b-navbar-nav>
                </div>
              </div>
              <div class="w-[95%]">
                <p
                    v-if="chart.selectOptions"
                    class="mb-0 text-[15px] font-semibold"
                    v-text="`${$t(chart.cardTitle).chartTitle} (${$t(chart.chooseOption.label)})`"
                />
                <p
                    v-else
                    class="mb-0 text-[15px] font-semibold"
                    v-text="$t(chart.cardTitle).chartTitle"
                />
              </div>
            </div>
            <div class="w-full pt-[10px] lg:pt-[0px] lg:w-[40%]"
                 v-if="chart.hasOwnProperty('chooseOption') && chart.mode === 'SALES'">
              <div class="h-full">

              </div>
            </div>
          </div>
          <div class="w-full py-[10px] flex">
            <div class="w-[100%] flex justify-center">
              <div
                  v-if="chart.hasOwnProperty('tabs')"
                  class="bg-secondaryColor d-flex bigGap align-items-center rounded-[5px] px-[10px] py-[6px]"
                  :style="
                        chart.tabs && chart.tabs.length < 3
                          ? ''
                          : ''
                      "
              >
                      <span v-for="(data, index) in chart.tabs" :key="index">
                      <p
                          v-if="data.active"
                          class="bg-[#f6f6f6] mb-[0px] border-[1px] px-[15px] rounded-[2px] border-[#f6f6f6]"
                          v-text="$t(data.label)"
                      />

                        <p
                            v-else
                            @click="switchTabs(chartIndex, index, chart.extra)"
                            class="mb-0 text-light px-[15px] cursor-pointer"
                            style="font-size: 15px"
                            role="button"
                            v-text="$t(data.label)"
                        />
                      </span>
              </div>
            </div>
            <div class="w-[10%] flex justify-end">
              <span
                  v-b-tooltip="chart.info ? chart.info[messageInfo] : ''"
              >
                      <mdicon
                          role="button"
                          :width="20"
                          :height="20"
                          name="help-circle-outline"
                      />
                    </span>
            </div>
          </div>
        </div>
        <b-card-title class="hidden">
                    <span class="">
                        <b-navbar>
                            <div
                                v-if="chart.selectOptions"
                                class="d-flex align-items-center"
                            >   
                            <!-- position-absolute -->
                                <b-navbar-nav class="nav align-items-center">
                                    <b-nav-item-dropdown
                                        no-caret
                                        id="dropdown-grouped"
                                        variant="link"
                                        class="dropdown-language p-0"
                                    >
                                        <template class="p-0" #button-content>
                                            <img
                                                src="@/assets/icon/chevronDown.png"
                                                width="20"
                                            />
                                        </template>

                                        <b-dropdown-item
                                            v-for="(
                                                label, index
                                            ) in chart.allOptions"
                                            :key="index"
                                            @click="
                                                switchOption(chartIndex, label, chart.extra)
                                            "
                                        >
                                            <span
                                                class="ml-50"
                                                v-text="$t(label.label)"
                                            />
                                        </b-dropdown-item>
                                    </b-nav-item-dropdown>
                                </b-navbar-nav>
                            </div>
                                
                            <b-row
                                class="w-100"
                                :align-h="
                                    chart.hasOwnProperty('tabs')
                                        ? 'center'
                                        : 'between'
                                "
                            >
                                <b-col
                                    cols="6"
                                    :class="{left35: chart.selectOptions}"
                                >
                                    <div class="d-flex smallGap">
                                        <p
                                            v-if="chart.selectOptions"
                                            class="mb-0"
                                            style="
                                                font-size: 15px;
                                                width: 200px;
                                            "
                                            v-text="
                                                $t(chart.cardTitle, {
                                                    option: $t(
                                                        chart.chooseOption.label
                                                    ),
                                                }).chartTitle
                                            "
                                        />
                                        <p
                                            v-else
                                            class="mb-0"
                                            style="font-size: 15px"
                                            v-text="$t(chart.cardTitle).chartTitle"
                                        />
                                    </div>
                                </b-col>

                                <b-col
                                    :cols="chart.tabs && chart.tabs.length < 3 ? 6 : 6"
                                    v-if="chart.hasOwnProperty('tabs')"
                                >
                                    <div
                                        class="bg-secondaryColor d-flex bigGap align-items-center tabWrapperSmall"
                                        :style="chart.tabs && chart.tabs.length < 3 ? 'width:10rem':''"
                                    >
                                    <!-- width: 120px -->
                                        <span
                                            v-for="(data, index) in chart.tabs"
                                            :key="index"
                                        >
                                            <b-button
                                                v-if="data.active"
                                                size="sm"
                                                variant="light"
                                                v-text="data.label"
                                            />

                                            <p
                                                v-else
                                                @click="
                                                    switchTabs(
                                                        chartIndex,
                                                        index,
                                                        chart.extra
                                                    )
                                                "
                                                class="mb-0 text-light"
                                                style="font-size: 15px"
                                                role="button"
                                                v-text="data.label"
                                            />
                                        </span>
                                    </div>
                                </b-col>

                              <!-- <b-col cols="2" v-if="chart.tabs && chart.tabs.length < 3">
                                  <mdicon
                                      role="button"
                                      :width="20"
                                      :height="20"
                                      name="help-circle-outline"
                                  />
                                  <img v-img="chart" height="20" />
                              </b-col> -->
                            </b-row>
                        </b-navbar>
                    </span>

          <!-- <span class="d-block d-lg-none">
              <div
                  class="w-100 align-items-center d-flex justify-content-between"
              >
                  <div class="d-flex smallGap">
                      <p class="mb-0" v-text="$t(chart.title)" />
                  </div>

                  <div class="d-flex justify-content-center">
                      <div
                          class="bg-secondaryColor d-flex bigGap align-items-center tabWrapperSmall"
                      >
                          <span
                              v-for="(data, index) in chart.tabs"
                              :key="index"
                          >
                              <b-button
                                  v-if="data.active"
                                  size="sm"
                                  variant="light"
                                  v-text="data.label"
                              />

                              <p
                                  v-else
                                  @click="
                                      switchTabs(chartIndex, index)
                                  "
                                  class="mb-0 text-light"
                                  style="font-size: 15px"
                                  role="button"
                                  v-text="data.label"
                              />
                          </span>
                      </div>
                  </div>

                  <div class="d-flex align-items-center smallGap">
                      <mdicon
                          role="button"
                          :width="20"
                          :height="20"
                          name="help-circle-outline"
                      />
                      <img v-img="chart" height="20" />
                  </div>
              </div>
          </span> -->
        </b-card-title>

        <b-card-text>
          <apexchart
              :type="chart.type"
              :options="chart.options"
              :series="chart.series"
              height="300px"
          />
        </b-card-text>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {dummyChartMixins} from './dummyChartMixins';

export default {
  mixins: [dummyChartMixins],
  name: 'contrats',
  data() {
    return {
      chartType: ['area', 'bar', 'bar', 'polarArea'],
      tabs: [
        {
          label: 'Incl.',
          active: true,
        },
        {
          label: 'Excl.',
          active: false,
        },
      ],
    };
  },
  methods: {
    switchTabs(chartIndex, index, extra = null) {
      let curentTabs = this.invoicesChart[chartIndex].tabs;
      curentTabs.find(item => item.active === true).active = false;
      curentTabs[index].active = true;
      this.reconstructGraph(chartIndex, null, extra)
    },

    switchOption(chartIndex, value, extra) {
      this.invoicesChart[chartIndex].chooseOption = value;

      this.reconstructGraph(chartIndex, value.value, extra)
    },

    reconstructGraph(chartIndex, value = null, extra = false) {
      if (extra) {
        this.renderExtraGraph(chartIndex, value, extra)
      }
    },

    renderExtraGraph(chartIndex, value, extra) {
      if (this.invoicesChart[chartIndex].tabs) {
        let currentTabs = this.invoicesChart[chartIndex].tabs.find(item => item.active === true);

        let currentData = null

        if (this.invoicesChart[chartIndex].chooseOption) {
          currentData = value ?
              this.invoicesChart[chartIndex][value] : this.invoicesChart[chartIndex][this.invoicesChart[chartIndex].chooseOption.value]
        } else {
          currentData = this.invoicesChart[chartIndex]['full']
        }
        this.invoicesChart[chartIndex].series = [
          {
            name: this.$t(
                this.invoicesChart[chartIndex].chartLabel
            ),
            data: Object.keys(currentData).map(item => {
              return `${currentData[item][currentTabs.invoiceAmnt]}`;
            }),
          },
        ];

        this.invoicesChart[chartIndex].options = {
          xaxis: {
            categories: Object.keys(currentData).map(item => {
              return `${item}`;
            }),
          },
        };
      }
    },


    async getBilled() {
      const chartIndex = 0
      const {data} = await this.getInvoiceBilled()
      const arrayData = data.data

      const chartOption =
          this.invoicesChart[chartIndex].chooseOption.value;
      const vatActive = this.invoicesChart[chartIndex].tabs.find(
          tab => tab.active == true
      );

      Object.keys(arrayData).forEach(item => {
        this.invoicesChart[chartIndex][item] = arrayData[item];

      });


      let currentData = this.invoicesChart[chartIndex][chartOption];


      this.invoicesChart[chartIndex].extra = true
      this.invoicesChart[chartIndex].series = [
        {
          name: this.$t('statistics.invoice'),
          data: Object.keys(currentData).map(item => {
            return `${currentData[item][vatActive.invoiceAmnt]}`;
          }),
        },
      ];

      this.invoicesChart[chartIndex].options = {
        xaxis: {
          categories: Object.keys(currentData).map(item => {
            return `${item}`;
          }),
        },
      };
      this.invoicesChart[chartIndex].info = {swe_info: data.swe_info, eng_info: data.eng_info}
    },

    async getPaymentReceived() {
      const chartIndex = 1
      const {data} = await this.paymentReceived()
      const arrayData = data.data

      const chartOption =
          this.invoicesChart[chartIndex].chooseOption.value;
      const vatActive = this.invoicesChart[chartIndex].tabs.find(
          tab => tab.active == true
      );

      Object.keys(arrayData).forEach(item => {
        this.invoicesChart[chartIndex][item] = arrayData[item];

      });

      let currentData =
          this.invoicesChart[chartIndex][chartOption];

      this.invoicesChart[chartIndex].extra = true
      this.invoicesChart[chartIndex].series = [
        {
          name: this.$t('statistics.invoice'),
          data: Object.keys(currentData).map(item => {
            return `${currentData[item][vatActive.invoiceAmnt]}`;
          }),
        },
      ];

      this.invoicesChart[chartIndex].options = {
        xaxis: {
          categories: Object.keys(currentData).map(item => {
            return `${item}`;
          }),
        },
      };
      this.invoicesChart[chartIndex].info = {swe_info: data.swe_info, eng_info: data.eng_info}
    },

    async getLostRevenue() {
      const chartIndex = 2
      const {data} = await this.lostRevenue()
      const arrayData = data.data

      // const chartOption =
      //     this.invoicesChart[chartIndex].chooseOption.value;
      const vatActive = this.invoicesChart[chartIndex].tabs.find(
          tab => tab.active === true
      );

      this.invoicesChart[chartIndex]['full'] = arrayData
      let currentData = {...this.invoicesChart[chartIndex]['full']}


      this.invoicesChart[chartIndex].extra = true
      this.invoicesChart[chartIndex].series = [
        {
          name: this.$t('statistics.invoice'),
          data: Object.keys(currentData).map(item => {
            return `${currentData[item][vatActive.invoiceAmnt].toFixed(2)}`;
          }),
        },
      ];

      this.invoicesChart[chartIndex].options = {
        xaxis: {
          categories: Object.keys(currentData).map(item => {
            return `${item}`;
          }),
        },
      };
      this.invoicesChart[chartIndex].info = {swe_info: data.swe_info, eng_info: data.eng_info}
    },

    async getUnPaidOverDue() {
      const chartIndex = 3
      const {data} = await this.unPaidOverdue()
      const arrayData = data.data

      // const chartOption =
      //     this.invoicesChart[chartIndex].chooseOption.value;
      const vatActive = this.invoicesChart[chartIndex].tabs.find(
          tab => tab.active == true
      );

      this.invoicesChart[chartIndex]['full'] = arrayData
      let currentData = {...this.invoicesChart[chartIndex]['full']}


      this.invoicesChart[chartIndex].extra = true
      this.invoicesChart[chartIndex].series = [
        {
          name: this.$t('statistics.invoice'),
          data: Object.keys(currentData).map(item => {
            return `${currentData[item][vatActive.invoiceAmnt]}`;
          }),
        },
      ];

      this.invoicesChart[chartIndex].options = {
        xaxis: {
          categories: Object.keys(currentData).map(item => {
            return `${item}`;
          }),
        },
      };
      this.invoicesChart[chartIndex].info = {swe_info: data.swe_info, eng_info: data.eng_info}
    }

  },
  mounted() {
    this.getBilled()
    this.getPaymentReceived()
    this.getLostRevenue()
    this.getUnPaidOverDue()
  }
};
</script>
