import useAppConfig from '@core/app-config/useAppConfig';

export const customersmixins = {
    data() {
        return {
            config: useAppConfig(),
        }
    },
    watch: {
        getLang() {
            this.customersChart.forEach(chart => {
                chart.options = {
                    xaxis: {
                        title: {
                            text: this.$t(chart.xaxisLabel),
                        },
                    },
                    title: {
                        text: this.$t(chart.chartLabel),
                    },
                };

                this.init();
            });
        },
    },
    computed: {
        messageInfo() {
            if (this.getLanguage == '') {
                return this.swedishLang ? 'swe_info' : 'eng_info';
            } else return this.getLanguage == 'sv' ? 'swe_info' : 'eng_info';
        },
        getLanguage() {
            return this.config.lang.value;
        },
    },
    methods: {
        async getSalesCustomer(chartIndex) {
            return this.$useJwt.getCustomersChart('historic_sales')
        },
        async getLatePaying(chartIndex) {
            return this.$useJwt.getCustomersChart(`average_lateness?t=${new Date().getMilliseconds()}`)
        },
        async getBenefitCost(chartIndex) {
            return this.$useJwt.getCustomersChart('benefit_cost_ratio')
        },

        async historicSales(searchData) {
            return this.$useJwt.getStatistics({URL: `/customers/historic_sales${searchData}`, method: 'get'})
        },

        async getHistoryCallLatePaying(chartIndex) {
            return this.$useJwt.getCustomersChart('late_paying')
        },
    },
};
