<template>
  <b-row>
    <b-col cols="12" class="">
      <div class="d-flex justify-content-center">
        <div
            class="bg-secondaryColor d-flex bigGap align-items-center tabWrapper"
        >
                    <span v-for="(data, index) in tabs" :key="index">
                        <b-button
                            v-if="data.active"
                            variant="light"
                            v-text="$t(data.label)"
                        />

                        <p
                            v-else
                            @click="switchTabs(index)"
                            class="mb-0 text-light"
                            role="button"
                            v-text="$t(data.label)"
                        />
                    </span>
        </div>
      </div>
    </b-col>
    <b-col cols="12">
      <KeepAlive>
        <transition name="zoom-fade" mode="out-in">
          <component :is="currentComponent"/>
        </transition>
      </KeepAlive>
    </b-col>
    <Modal

        id="modal-access-denied"
        :hideFooter="true"
        :no-close-on-backdrop="true"
        :hide-header="true"
        :hide-back-drop="true"
    >
      <access-denied/>
    </Modal>
  </b-row>
</template>

<script>
import contracts from '@/@core/components/accountingData/statistics/contracts.vue';
import general from '@/@core/components/accountingData/statistics/general.vue';
import customers from '@/@core/components/accountingData/statistics/customers.vue';
import products from '@/@core/components/accountingData/statistics/products.vue';
import invoices from '@/@core/components/accountingData/statistics/invoices.vue';
import accessDenied from "@core/components/permission/accessDenied";

export default {
  name: 'statistics',
  components: {
    contracts,
    general,
    customers,
    products,
    invoices,
    accessDenied
  },
  data() {
    return {
      currentComponent: 'contracts',
      tabs: [
        {
          label: 'StatisticsCard.tabs.contracts',
          components: 'contracts',
          active: true,
        },
        {
          label: 'StatisticsCard.tabs.customers',
          components: 'customers',
          active: false,
        },
        {
          label: 'StatisticsCard.tabs.invoices',
          components: 'invoices',
          active: false,
        },
        {
          label: 'StatisticsCard.tabs.products',
          components: 'products',
          active: false,
        },
        // {
        //   label: 'StatisticsCard.tabs.general',
        //   components: 'general',
        //   active: false,
        // },
      ],
      locale: {},
    };
  },

  methods: {
    switchTabs(index) {
      this.tabs.find(item => item.active == true).active = false;

      this.currentComponent = this.tabs[index].components;

      this.tabs[index].active = true;
    },
    getUserSubscriptions() {
      this.$useJwt.subscription().catch(err => {
        if (err.response.status === 403) {
          window.Bus.$emit('trigger-access-denied', {'data': err.response.data})
        }
      })
    }
  },
  mounted() {
    this.$store.commit('appConfig/UPDATE_ACCESS_DENIED', false)
    this.getUserSubscriptions()
  }
};
</script>
