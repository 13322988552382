<template>
  <b-row class="mt-5">
    <b-col
        v-for="(chart, indexTabs) in contractsChart"
        :key="indexTabs"
        cols="12"
        lg="6"
    >
      <b-card style="height: 430px">
        <div class="w-full  bg-white">
          <div class=" mb-[15px] block lg:flex">
            <div class="w-full flex gap-3">

              <div class="w-full">
                <p
                    v-if="chart.selectOptions"
                    class="mb-0 text-[15px] font-semibold"
                    v-text="
                          $t(chart.cardTitle, {
                            option: $t(chart.chooseOption.label),
                          })
                        "
                />
                <p
                    v-else
                    class="mb-0 text-[15px] font-semibold"
                    v-text="
                          $t(chart.cardTitle, {
                            option: chart.optionText ? chart.optionText : '',
                          })
                        "
                />
              </div>
            </div>
            <div class="w-full pt-[10px] lg:pt-[0px] lg:w-[40%]"
                 v-if="chart.hasOwnProperty('chooseOption') && chart.mode === 'SALES'">
            </div>
          </div>
          <div class="w-full py-[10px] flex">
            <div class="w-[90%] flex justify-center">
              <div
                  v-if="chart.hasOwnProperty('tabs')"
                  class="bg-secondaryColor flex bigGap justify-center rounded-[5px] px-[10px] py-[6px]"
                  :style="
                        chart.tabs && chart.tabs.length < 3
                          ? 'width: 170px'
                          : ''
                      "
              >
                      <span v-for="(data, index) in chart.tabs" :key="index">
                        <p
                            v-if="data.active"
                            class="bg-[#f6f6f6] mb-[0px] border-[1px] px-[15px] rounded-[2px] border-[#f6f6f6]"
                            v-text="$t(data.label_lang)"
                        />

                        <p
                            v-else
                            @click="switchTabs(chart.tabs, index)"
                            class="mb-0 text-light cursor-pointer"
                            style="font-size: 15px"
                            role="button"
                            v-text="$t(data.label_lang)"
                        />
                      </span>
              </div>
            </div>
            <div class="w-[10%] flex justify-end">
              <span
                  v-b-tooltip="chart.info ? chart.info[messageInfo] : ''"
              >
                      <mdicon
                          role="button"
                          :width="20"
                          :height="20"
                          name="help-circle-outline"
                      />
                    </span>
            </div>
          </div>
        </div>
        <b-card-title class="hidden">
          <span class="d-none d-lg-block">
            <b-row
                :align-h="chart.hasOwnProperty('tabs') ? 'center' : 'between'"
            >
              <b-col cols="6">
                <div class="d-flex smallGap">
                  <p class="mb-0" v-text="$t(chart.cardTitle)"/>
                </div>
              </b-col>

              <b-col cols="4" v-if="chart.hasOwnProperty('tabs')">
                <div
                    class="bg-secondaryColor d-flex bigGap align-items-center tabWrapperSmall"
                    style="width: 120px"
                >
                  <span v-for="(data, index) in chart.tabs" :key="index">
                    <p
                        v-if="data.active"
                        class="bg-[#f6f6f6] border-[1px] border-[#f6f6f6]"
                        v-text="data.label"
                    />

                    <p
                        v-else
                        @click="switchTabs(chart.tabs, index)"
                        class="mb-0 text-light"
                        style="font-size: 15px"
                        role="button"
                        v-text="data.label"
                    />
                  </span>
                </div>
              </b-col>

              <b-col cols="2">
                <span v-b-tooltip="chart.info ? chart.info[messageInfo] : ''">
                  <mdicon
                      role="button"
                      :width="20"
                      :height="20"
                      name="help-circle-outline"
                  />
                </span>
                <!--                <img v-img="chart" height="20" />-->
              </b-col>
            </b-row>
          </span>

          <span class="d-block d-lg-none">
            <div
                class="w-100 align-items-center d-flex justify-content-between"
            >
              <div class="d-flex smallGap">
                <p class="mb-0" v-text="$t(chart.cardTitle)"/>
              </div>

              <div class="d-flex justify-content-center" v-if="chart.hasOwnProperty('tabs')">
                <div
                    class="bg-secondaryColor d-flex bigGap align-items-center tabWrapperSmall"
                >
                  <span v-for="(data, index) in chart.tabs" :key="index">
                    <b-button
                        v-if="data.active"
                        size="sm"
                        variant="light"
                        v-text="data.label"
                    />

                    <p
                        v-else
                        @click="switchTabs(chart.tabs, index)"
                        class="mb-0 text-light"
                        style="font-size: 15px"
                        role="button"
                        v-text="data.label"
                    />
                  </span>
                </div>
              </div>

              <div class="d-flex align-items-center smallGap">
                <span v-b-tooltip="chart.info ? chart.info[messageInfo] : ''">
                  <mdicon
                      role="button"
                      :width="20"
                      :height="20"
                      name="help-circle-outline"
                  />
                </span>
                <img v-img="chart" height="20"/>
              </div>
            </div>
          </span>
        </b-card-title>
        <b-card-text>
          <apexchart
              :type="chart.type"
              height="300"
              :options="chart.options"
              :series="chart.series"
          />
        </b-card-text>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {dummyChartMixins} from "./dummyChartMixins";

export default {
  mixins: [dummyChartMixins],
  name: "contracts",
  data: function () {
    return {
      commonIntervalList: []
    }
  },
  watch: {
    getLang() {
      this.contractsChart.forEach((chart) => {
        if (chart.type !== "polarArea") {
          chart.options = {
            xaxis: {
              title: {
                text: this.$t(chart.xaxisLabel),
              },
            },
            title: {
              text: this.$t(chart.chartTitle),
            },
          };
          this.init();
        }
      });
    },
  },
  methods: {
    switchTabs(tabs, index) {
      tabs.find((item) => item.active === true).active = false;
      tabs[index].active = true;

      let chartTitle = this.contractsChart[1].chartTitle;
      let tabValue = this.contractsChart[1].tabs[index].tabValue;

      this.contractsChart[1].options = {
        xaxis: {
          categories: Object.keys(tabValue).map((categories) =>
              categories
          ),
        },
      };

      this.contractsChart[1].series = [
        {
          name: this.$t(chartTitle),
          data: Object.keys(tabValue).map((item) => tabValue[item]),
        },
      ];
    },
    getCreatedContracts() {
      this.$useJwt.getContractsChart("created_last_12_months").then((res) => {
        const {data} = res.data;
        const info = {
          swe_info: res.data.swe_info,
          eng_info: res.data.eng_info,
        };

        this.contractsChart[0].series = [
          {
            langObject: "StatisticsCard.contractsTab.chartLabel1",
            name: this.$t("StatisticsCard.contractsTab.chartLabel1"),
            data: Object.keys(data).map((item) => {
              return data[item];
            }),
          },
        ];

        this.contractsChart[0].options = {
          xaxis: {
            categories: Object.keys(data).map((item) => item.substring(0, 3)),
          },
        };
        this.contractsChart[0].info = info;
      });
    },
    getAverageBilling() {
      this.$useJwt.getContractsChart("recurring_revenue").then((res) => {
        const {data} = res.data;
        const info = {
          swe_info: res.data.swe_info,
          eng_info: res.data.eng_info,
        };

        Object.keys(data).forEach((key, index) => {
          this.contractsChart[1].tabs[index].tabValue = data[key];
        });

        this.contractsChart[1].series = [
          {
            name: this.$t("StatisticsCard.contractsTab.chartLabel2"),
            data: Object.keys(data.inc_vat).map((item) => {
              return data.inc_vat[item];
            }),
          },
        ];
// .replace(/[^a-zA-Z ]/g, " ")
        this.contractsChart[1].options = {
          xaxis: {
            categories: Object.keys(data.inc_vat).map((categories) =>
                categories
            ),
          },
        };
        this.contractsChart[1].info = info;
      });
    },
    getContractLength() {
      this.$useJwt
          .getContractsChart("contracts_per_contractlength")
          .then((res) => {
            const {data} = res.data;
            const info = {
              swe_info: res.data.swe_info,
              eng_info: res.data.eng_info,
            };

            const sortingData = Object.keys(data).sort((a, b) => b - a);

            this.contractsChart[2].series = [
              {
                name: this.$t("StatisticsCard.contractsTab.chartLabel3"),
                data: Object.keys(data).map(
                    (item, index) => data[sortingData[index]].amount_of_contracts
                ),
              },
            ];

            this.contractsChart[2].options = {
              xaxis: {
                categories: sortingData,
              },
            };
            this.contractsChart[2].info = info;
          });
    },
    getCommonInterval() {
      // function formatAsPercent(num) {
      //     return new Intl.NumberFormat('default', {
      //         style: 'percent',
      //         minimumFractionDigits: 2,
      //         maximumFractionDigits: 2,
      //     }).format(num / 100);
      // }

      this.$useJwt
          .getContractsChart("most_common_invoice_interval")
          .then((res) => {
            const {data} = res.data;
            const info = {
              swe_info: res.data.swe_info,
              eng_info: res.data.eng_info,
            };
            this.commonIntervalList = []
            Object.keys(data).map(k => {
              this.commonIntervalList.push({
                num_of_contract: k,
                amount_of_contracts: data[k].amount_of_contracts,
                percentage: data[k].percentage,
              })
            })

            // //Series
            this.contractsChart[3].series = Object.keys(data).map(
                (item) => data[item].amount_of_contracts
            );
            // //Categories
            this.contractsChart[3].options = {
              labels: Object.keys(data).map((item) => item),
            };

            this.contractsChart[3].info = info;
          });
    },

    init() {
      this.getCreatedContracts();
      this.getAverageBilling();
      this.getContractLength();
      this.getCommonInterval();
    },
  },

  mounted() {
    this.init();
  },
};
</script>

<style></style>
