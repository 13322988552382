<template>
  <b-row class="mt-5">
    <b-col
        v-for="(chart, chartIndex) in generalChart"
        :key="chartIndex"
        cols="12"
        lg="6"
    >
      <b-card>
        <div class="w-full  bg-white">
          <div class=" mb-[15px] block lg:flex">
            <div class="w-full flex gap-3">

              <div class="w-full">
                <p
                    v-if="chart.selectOptions"
                    class="mb-0 text-[15px] font-semibold"
                    v-text="
                          $t(chart.cardTitle, {
                            option: $t(chart.chooseOption.label),
                          })
                        "
                />
                <p
                    v-else
                    class="mb-0 text-[15px] font-semibold"
                    v-text="
                          $t(chart.cardTitle, {
                            option: chart.optionText ? chart.optionText : '',
                          })
                        "
                />
              </div>
            </div>
            <div class="w-full pt-[10px] lg:pt-[0px] lg:w-[40%]"
                 v-if="chart.hasOwnProperty('chooseOption') && chart.mode === 'SALES'">
            </div>
          </div>
          <div class="w-full py-[10px] flex">
            <div class="w-[90%] flex justify-center">
              <div
                  v-if="chart.hasOwnProperty('tabs')"
                  class="bg-secondaryColor d-flex bigGap align-items-center rounded-[5px] px-[10px] py-[6px]"
                  :style="
                        chart.tabs && chart.tabs.length < 3
                          ? ''
                          : ''
                      "
              >
                      <span v-for="(data, index) in chart.tabs" :key="index">
                       <p
                            v-if="data.active"
                            class="bg-[#f6f6f6] mb-[0px] border-[1px] px-[15px] rounded-[2px] border-[#f6f6f6]"
                            v-text="$t(data.label)"
                        />

                        <p
                            v-else
                            @click="switchTabs(
                                                        chartIndex,
                                                        index,
                                                        chart.extra
                                                    )"
                            class="mb-0 text-light px-[15px]"
                            style="font-size: 15px"
                            role="button"
                            v-text="$t(data.label)"
                        />
                      </span>
              </div>
            </div>
            <div class="w-[10%] flex justify-end">
              <span
                  v-b-tooltip="chart.info ? chart.info[messageInfo] : ''"
              >
                      <mdicon
                          role="button"
                          :width="20"
                          :height="20"
                          name="help-circle-outline"
                      />
                    </span>
              <small @click="openModalForArticles(chart.mode)" v-if="chart.searchArticle || chart.searchCustomer"
                     class="cursor-pointer text-theme-sidebar font-semibold">Sök</small>

            </div>
          </div>
        </div>
        <b-card-title class="hidden">
                    <span class="">
                        <b-navbar>
                            <div
                                v-if="chart.selectOptions"
                                class="d-flex align-items-center"
                            >   
                            <!-- position-absolute -->
                                <b-navbar-nav class="nav align-items-center">
                                    <b-nav-item-dropdown
                                        no-caret
                                        id="dropdown-grouped"
                                        variant="link"
                                        class="dropdown-language p-0"
                                    >
                                        <template class="p-0" #button-content>
                                            <img
                                                src="@/assets/icon/chevronDown.png"
                                                width="20"
                                            />
                                        </template>

                                        <b-dropdown-item
                                            v-for="(
                                                label, index
                                            ) in chart.allOptions"
                                            :key="index"
                                            @click="
                                                switchOption(chartIndex, label, chart.extra)
                                            "
                                        >
                                            <span
                                                class="ml-50"
                                                v-text="$t(label.label)"
                                            />
                                        </b-dropdown-item>
                                    </b-nav-item-dropdown>
                                </b-navbar-nav>
                            </div>
                                
                            <b-row
                                class="w-100"
                                :align-h="
                                    chart.hasOwnProperty('tabs')
                                        ? 'center'
                                        : 'between'
                                "
                            >
                                <b-col
                                    cols="6"
                                    :class="{left35: chart.selectOptions}"
                                >
                                    <div class="d-flex smallGap">
                                        <p
                                            v-if="chart.selectOptions"
                                            class="mb-0"
                                            style="
                                                font-size: 15px;
                                                width: 200px;
                                            "
                                            v-text=" chartIndex === 1 ? 
                                                $t(chart.cardTitle, {
                                                    option: $t(
                                                        chart.chooseOption.label
                                                    ),
                                                }) : $t(chart.cardTitle, {
                                                    option: searchArticleForm.value
                                                }) 
                                            "
                                        />
                                        <p
                                            v-else
                                            class="mb-0"
                                            style="font-size: 15px"
                                            v-text="
                                                chartIndex === 1 ? 
                                                $t(chart.cardTitle) : $t(chart.cardTitle, {
                                                    option: chart.searchArticle ? searchArticleForm.value : chart.searchCustomer ? 
                                                    searchCustomerForm.value : ''})
                                            "
                                        />
                                    </div>
                                </b-col>

                                <b-col
                                    :cols="chart.tabs && chart.tabs.length < 3 ? 4 : 6"
                                    v-if="chart.hasOwnProperty('tabs')"
                                >
                                    <div
                                        class="bg-secondaryColor d-flex bigGap align-items-center tabWrapperSmall"
                                        :style="chart.tabs && chart.tabs.length < 3 ? 'width:110px':''"
                                    >
                                    <!-- width: 120px -->
                                        <span
                                            v-for="(data, index) in chart.tabs"
                                            :key="index"
                                        >
                                            <b-button
                                                v-if="data.active"
                                                size="sm"
                                                variant="light"
                                                v-text="data.label"
                                            />

                                            <p
                                                v-else
                                                @click="
                                                    switchTabs(
                                                        chartIndex,
                                                        index,
                                                        chart.extra
                                                    )
                                                "
                                                class="mb-0 text-light"
                                                style="font-size: 15px"
                                                role="button"
                                                v-text="data.label"
                                            />
                                        </span>
                                    </div>
                                </b-col>

                                <b-col cols="2" class="flex items-center gap-1">
                                    <span v-b-tooltip="chart.info ? chart.info[messageInfo] : '' ">
                                        <mdicon
                                            role="button"
                                            :width="20"
                                            :height="20"
                                            name="help-circle-outline"
                                        />
                                    </span>
                                    <small @click="openModalForArticles(chart.mode)"
                                           v-if="chart.searchArticle || chart.searchCustomer"
                                           class="cursor-pointer text-theme-sidebar font-semibold">Sök</small>
                                    <img v-img="chart" height="20"/>
                                </b-col>
                            </b-row>
                        </b-navbar>
                    </span>

          <!-- <span class="d-block d-lg-none">
              <div
                  class="w-100 align-items-center d-flex justify-content-between"
              >
                  <div class="d-flex smallGap">
                      <p class="mb-0" v-text="$t(chart.title)" />
                  </div>

                  <div class="d-flex justify-content-center">
                      <div
                          class="bg-secondaryColor d-flex bigGap align-items-center tabWrapperSmall"
                      >
                          <span
                              v-for="(data, index) in chart.tabs"
                              :key="index"
                          >
                              <b-button
                                  v-if="data.active"
                                  size="sm"
                                  variant="light"
                                  v-text="data.label"
                              />

                              <p
                                  v-else
                                  @click="
                                      switchTabs(chartIndex, index)
                                  "
                                  class="mb-0 text-light"
                                  style="font-size: 15px"
                                  role="button"
                                  v-text="data.label"
                              />
                          </span>
                      </div>
                  </div>

                  <div class="d-flex align-items-center smallGap">
                      <mdicon
                          role="button"
                          :width="20"
                          :height="20"
                          name="help-circle-outline"
                      />
                      <img v-img="chart" height="20" />
                  </div>
              </div>
          </span> -->
        </b-card-title>

        <b-card-text>
          <apexchart
              :type="chart.type"
              :options="chart.options"
              :series="chart.series"
              height="300px"
          />
        </b-card-text>
        <small class="text-red-500" v-if="chart.full && chart.full.Info">{{ chart.full.Info }}</small>
      </b-card>
    </b-col>

    <CustomNoHeaderModalVue v-if="openModal" width="w-1/2 md:w-1/4" :title="''" @handleClose="openModal = false"
                            :close="!true">
      <div class="flex flex-col" slot="header">
        <div class="flex flex-col md:flex-row md:justify-between md:items-center gap-5 border-b p-1">
          <div class="flex flex-col">
            <h2 class="text-xl md:text-2xl font-semibold font-poppins text-gray-900">Sök</h2>
          </div>
        </div>
      </div>
      <div class="flex flex-col" slot="body">
        <div class="mb-1">
          <div class="flex flex-col h-60 mt-2">
            <div v-if="articleSearch">
              <b-form-group>
                <b-form-radio
                    v-model="searchArticleForm.radio"
                    name="radio-1"
                    value="ARTICLE">
                  <span>Articles</span>
                </b-form-radio>
                <b-form-radio
                    name="radio-1"
                    v-model="searchArticleForm.radio"
                    value="ARTICLE_SEGMENT">
                  <span>Article Segment</span>
                </b-form-radio>
              </b-form-group>
              <b-form-group>
                <v-select class="w-full" @option:selected="setData($event)" :filterable="false"
                          :options="articleResults" @search="searchData">
                  <template slot="no-options">
                    Sök...
                  </template>
                  <template slot="option" slot-scope="option">
                    <div class="d-center">
                      {{ option.label }}
                    </div>
                  </template>
                  <template slot="selected-option" slot-scope="option">
                    <div class="selected d-center">
                      {{ option.label }}
                    </div>
                  </template>
                </v-select>
              </b-form-group>
              <div class="flex">
                <button @click="performGraphSearch" :disabled="processing" type="submit"
                        class="flex w-1/2 c-p-2 text-center focus:outline-none bg-theme-teal text-white whitespace-nowrap rounded-md">
                                    <span class="w-full text-center" v-if="processing">
                                        <b-spinner
                                            small
                                            type="grow"
                                        />
                                        {{ $t('Message.loading') }}...
                                    </span>

                  <span
                      class="whitespace-nowrap text-center w-full text-base"
                      v-else
                      v-text="$t(' Message.loading')"
                  />
                </button>
              </div>

            </div>
            <div v-if="customerSearch">
              <b-form-group>
                <b-form-radio
                    v-model="searchCustomerForm.radio"
                    name="radio-1"
                    value="CUSTOMER">
                  <span>Customer</span>
                </b-form-radio>
                <b-form-radio
                    name="radio-1"
                    v-model="searchCustomerForm.radio"
                    value="CUSTOMER_SEGMENT">
                  <span>Customer Segment</span>
                </b-form-radio>
              </b-form-group>
              <b-form-group>
                <v-select class="w-full" @option:selected="setData($event)" :filterable="false"
                          :options="customerResults" @search="searchData">
                  <template slot="no-options">
                    Sök...
                  </template>
                  <template slot="option" slot-scope="option">
                    <div class="d-center">
                      {{ option.label }}
                    </div>
                  </template>
                  <template slot="selected-option" slot-scope="option">
                    <div class="selected d-center">
                      {{ option.label }}
                    </div>
                  </template>
                </v-select>
              </b-form-group>
              <div class="flex">
                <button @click="performGraphSearch" :disabled="processing" type="submit"
                        class="flex w-1/2 c-p-2 text-center focus:outline-none bg-theme-teal text-white whitespace-nowrap rounded-md">
                                    <span class="w-full text-center" v-if="processing">
                                        <b-spinner
                                            small
                                            type="grow"
                                        />
                                        {{ $t('Message.loading') }}...
                                    </span>

                  <span
                      class="whitespace-nowrap text-center w-full text-base"
                      v-else
                      v-text="
                                            Sök
                                        "
                  />
                </button>
              </div>

            </div>
          </div>
        </div>
      </div>
    </CustomNoHeaderModalVue>
  </b-row>

</template>

<script>
import CustomNoHeaderModalVue from '@/@core/customComponent/CustomNoHeaderModal.vue';
import {dummyChartMixins} from './dummyChartMixins';
import debounce from 'lodash.debounce'
import vSelect from 'vue-select';

export default {
  mixins: [dummyChartMixins],
  components: {CustomNoHeaderModalVue, vSelect},
  name: 'general',
  data() {
    return {
      openModal: false,
      processing: false,
      searchArticlePerform: false,
      searchCustomerPerform: false,
      articleSearch: false,
      customerSearch: false,
      tabs: [
        {
          label: 'Incl.',
          active: true,
        },
        {
          label: 'Excl.',
          active: false,
        },
      ],
      searchArticleForm: {
        value: '',
        radio: 'ARTICLE'
      },
      searchCustomerForm: {
        value: '',
        radio: 'CUSTOMER'
      },
      articleResults: [],
      customerResults: []

    };
  },
  methods: {
    openModalForArticles(data) {
      if (data === 'ARTICLE') {
        this.articleSearch = true
        this.customerSearch = false
      } else {
        this.customerSearch = true
        this.articleSearch = false
      }
      this.openModal = true
    },
    searchData(search, loading) {
      if (search.length) {
        loading(true);
        this.searchRemoteData(loading, search, this);
      }
    },
    searchRemoteData: debounce(function (loading, search, vm) {
      let URL = ''
      if (this.articleSearch) {
        URL = this.searchArticleForm.radio === 'ARTICLE' ? 'articles?search=' + escape(search) : 'segments/articles?search=' + escape(search)
      } else {
        URL = this.searchCustomerForm.radio === 'CUSTOMER' ? 'customers?search=' + escape(search) : 'segments/customers?search=' + escape(search)
      }
      this.$useJwt.useBaseURLOnly(URL)
          .then(res => {
            if (this.articleSearch) {
              if (this.searchArticleForm.radio === 'ARTICLE') {
                this.articleResults = res.data.data.map(el => {
                  return {label: el.article_number, value: el.article_number}
                })
              } else {
                this.articleResults = res.data.results.map(el => {
                  return {label: el.name, value: el.id}
                })
              }
            } else {
              if (this.searchCustomerForm.radio === 'CUSTOMER') {
                this.customerResults = res.data.data.map(el => {
                  return {label: el.name, value: el.customer_number}
                })
              } else {
                this.customerResults = res.data.results.map(el => {
                  return {label: el.name, value: el.id}
                })
              }
            }
            loading(false);
          })
          .catch(err => {
          })
    }, 350),
    setData(item) {
      if (this.articleSearch) {
        this.searchArticleForm.value = item.value
      } else {
        this.searchCustomerForm.value = item.value
      }
    },
    performGraphSearch() {
      this.processing = true
      const payload = {}
      if (this.articleSearch) {
        if (this.searchArticleForm.radio === 'ARTICLE') {
          payload['article_number'] = this.searchArticleForm.value
        } else {
          payload['segment_id'] = this.searchArticleForm.value
        }
        this.getPrognosisArticle(payload)
      } else {
        if (this.searchCustomerForm.radio === 'CUSTOMER') {
          payload['customer_number'] = this.searchCustomerForm.value
        } else {
          payload['segment_id'] = this.searchCustomerForm.value
        }
        this.getPrognosisCustomer(payload)
      }
    },
    switchTabs(chartIndex, index, extra = null) {
      let curentTabs = this.generalChart[chartIndex].tabs;
      curentTabs.find(item => item.active == true).active = false;
      curentTabs[index].active = true;

      this.reconstructGraph(chartIndex, null, extra)
    },

    switchOption(chartIndex, value, extra) {
      this.generalChart[chartIndex].chooseOption = value;

      this.reconstructGraph(chartIndex, value.value, extra)
    },

    reconstructGraph(chartIndex, value = null, extra = false) {
      if (extra) {
        this.renderExtraGraph(chartIndex, value, extra)
      } else {
        if (this.generalChart[chartIndex].type === 'polarArea') {
          let currentTabs = this.generalChart[chartIndex].tabs.find(item => item.active == true);

          let currentData = value ?
              this.generalChart[chartIndex][value] : this.generalChart[chartIndex][this.generalChart[chartIndex].chooseOption.value]

          this.generalChart[chartIndex].series = currentData.map(item => {
            return item[currentTabs.productPrice]
          }),

              this.generalChart[chartIndex].options = {
                labels: currentData.map(item => {
                  return `Artikelnr. ${item.article_number}`;
                }),
              };

        } else {
          if (this.generalChart[chartIndex].tabs) {
            let currentTabs = this.generalChart[chartIndex].tabs.find(item => item.active == true);

            let currentData = value ?
                this.generalChart[chartIndex][value] : this.generalChart[chartIndex][this.generalChart[chartIndex].chooseOption.value]

            this.generalChart[chartIndex].series = [
              {
                name: this.$t(
                    this.generalChart[chartIndex].chartLabel
                ),
                data: currentData.map(item => {
                  return item[currentTabs.productPrice];
                }),
              },
            ];

            this.generalChart[chartIndex].options = {
              xaxis: {
                categories: currentData.map(item => {
                  return `Artikelnr. ${item.article_number}`;
                }),
              },
            };
          }

        }
      }
    },

    renderExtraGraph(chartIndex, value, extra) {
      if (this.generalChart[chartIndex].tabs) {
        let currentTabs = this.generalChart[chartIndex].tabs.find(item => item.active == true);

        let currentData = null

        if (this.generalChart[chartIndex].searchArticle && !this.searchArticlePerform) {
          return
        }

        if (this.generalChart[chartIndex].searchCustomer && !this.searchCustomerPerform) {
          return
        }

        if (this.generalChart[chartIndex].chooseOption) {
          currentData = value ?
              this.generalChart[chartIndex][value] : this.generalChart[chartIndex][this.generalChart[chartIndex].chooseOption.value]
        } else {
          currentData = this.generalChart[chartIndex]['full']
        }

        if (this.generalChart[chartIndex].unified) {
          if (Object.keys(currentData).length > 0) {
            Object.keys(currentData).map(el => {
              this.generalChart[chartIndex].options = {
                xaxis: {
                  categories: Object.keys(currentData[el]).map(item => {
                    return `${item}`;
                  }),
                },
              };
              const obj = {}
              obj.name = this.$t(
                  this.generalChart[chartIndex].chartLabel
              )
              obj.data = Object.keys(currentData[el]).map(item => {
                return `${currentData[el][item][currentTabs.salesAmount]}`;
              })
              this.generalChart[chartIndex].series.push(obj)
            })
          }
        } else {
          this.generalChart[chartIndex].series = [
            {
              name: this.$t(
                  this.generalChart[chartIndex].chartLabel
              ),
              data: Object.keys(currentData).map(item => {
                return `${currentData[item][currentTabs.salesAmount]}`;
              }),
            },
          ];

          this.generalChart[chartIndex].options = {
            xaxis: {
              categories: Object.keys(currentData).map(item => {
                return `${item}`;
              }),
            },
          };
        }
      }
    },

    async getSalesPrognosis() {
      const chartIndex = 0
      const {data} = await this.salesPrognosis()
      const arrayData = data.data
      const info = {swe_info: data.swe_info, eng_info: data.eng_info}

      const vatActive = this.generalChart[chartIndex].tabs.find(
          tab => tab.active == true
      );

      this.generalChart[chartIndex]['full'] = arrayData
      let currentData = {...this.generalChart[chartIndex]['full']}


      this.generalChart[chartIndex].extra = true
      this.generalChart[chartIndex].info = info

      this.generalChart[chartIndex].series = [
        {
          name: this.$t(
              this.generalChart[chartIndex].chartLabel
          ),
          data: Object.keys(currentData).map(item => {
            return `${currentData[item][vatActive.salesAmount]}`;
          }),
        },
      ];

      this.generalChart[chartIndex].options = {
        xaxis: {
          categories: Object.keys(currentData).map(item => {
            return `${item}`;
          }),
        },
      };
    },

    async getPrognosisArticle(searchParams) {
      const chartIndex = 1
      const {data} = await this.salesPrognosisArticle(searchParams)
      this.processing = false
      this.openModal = false

      const arrayData = data.data
      const info = {swe_info: data.swe_info, eng_info: data.eng_info}

      const vatActive = this.generalChart[chartIndex].tabs.find(
          tab => tab.active == true
      );

      this.generalChart[chartIndex]['full'] = arrayData
      let currentData = {...this.generalChart[chartIndex]['full']}


      this.generalChart[chartIndex].extra = true
      this.generalChart[chartIndex].info = info

      this.generalChart[chartIndex].series = [
        {
          name: this.$t(
              this.generalChart[chartIndex].chartLabel
          ),
          data: Object.keys(currentData).map(item => {
            return `${currentData[item][vatActive.salesAmount]}`;
          }),
        },
      ];

      this.generalChart[chartIndex].options = {
        xaxis: {
          categories: Object.keys(currentData).map(item => {
            return `${item}`;
          }),
        },
      };
      this.searchArticlePerform = true
    },

    async getPrognosisCustomer(searchParams) {
      const chartIndex = 2
      const {data} = await this.salesPrognosisCustomer(searchParams)
      this.processing = false
      this.openModal = false

      const arrayData = data.data
      const info = {swe_info: data.swe_info, eng_info: data.eng_info}

      const vatActive = this.generalChart[chartIndex].tabs.find(
          tab => tab.active == true
      );

      if (arrayData.Info) {
        // error message
        this.generalChart[chartIndex].error = arrayData.info
      } else {
        this.generalChart[chartIndex]['error'] = ''
      }

      this.generalChart[chartIndex]['full'] = arrayData
      let currentData = {...this.generalChart[chartIndex]['full']}

      this.generalChart[chartIndex].series = []

      if (Object.keys(currentData).length > 0) {
        Object.keys(currentData).map(el => {
          this.generalChart[chartIndex].options = {
            xaxis: {
              categories: Object.keys(currentData[el]).map(item => {
                return `${item}`;
              }),
            },
          };
          const obj = {}
          obj.name = this.$t(
              this.generalChart[chartIndex].chartLabel
          )
          obj.data = Object.keys(currentData[el]).map(item => {
            return `${currentData[el][item][vatActive.salesAmount]}`;
          })
          this.generalChart[chartIndex].series.push(obj)
        })
      }

      this.generalChart[chartIndex].extra = true
      this.generalChart[chartIndex].unified = true
      this.generalChart[chartIndex].info = info

      this.searchCustomerPerform = true
    }

  },
  mounted() {
    this.getSalesPrognosis()
  }
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>