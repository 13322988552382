import useAppConfig from '@core/app-config/useAppConfig';

export const dummyChartMixins = {
    directives: {
        img: {
            inserted(el, data) {
                const {favorite} = data.value;

                el.src = require(`@/assets/icon/${
                    favorite ? 'enableStar.png' : 'disableStar.png'
                }`);
            },
        },
    },
    data() {
        return {
            config: useAppConfig(),
            contractsChart: [
                {
                    type: 'area',
                    cardTitle:
                        'StatisticsCard.contractsTab.contractCreatedLabel.label',
                    chartTitle: 'StatisticsCard.contractsTab.chartLabel1',
                    xaxisLabel:
                        'StatisticsCard.contractsTab.contractCreatedLabel.xaxisLabel',

                    favorite: true,
                    series: [],
                    options: {
                        chart: {
                            zoom: {
                                enabled: false,
                            },
                            toolbar: {
                                show: false,
                            },
                        },
                        xaxis: {
                            type: 'category',
                            categories: [],
                            label: {
                                show: true,
                            },

                            title: {
                                text: this.$t(
                                    'StatisticsCard.contractsTab.contractCreatedLabel.xaxisLabel'
                                ),
                                style: {
                                    fontSize: '20px',
                                    fontWeight: 600,
                                    cssClass: 'chartTitle',
                                },
                            },
                        },
                        title: {
                            text: this.$t(
                                'StatisticsCard.contractsTab.chartLabel1'
                            ),
                            align: 'left',
                        },
                        dataLabels: {
                            enabled: false,
                        },
                        tooltip: {
                            enabled: true,
                            x: {
                                show: false,
                            },
                        },
                    },
                },
                {
                    type: 'bar',
                    cardTitle: 'StatisticsCard.contractsTab.averageLabel.label',
                    xaxisLabel:
                        'StatisticsCard.contractsTab.averageLabel.xaxisLabel',
                    chartTitle: 'StatisticsCard.contractsTab.chartLabel2',
                    endpoint: 'recurring_revenue',
                    favorite: false,

                    options: {
                        chart: {
                            id: 'vuechart-example',
                            zoom: {
                                enabled: false,
                            },
                            toolbar: {
                                show: false,
                            },
                        },
                        xaxis: {
                            label: {
                                show: true,
                            },
                            title: {
                                text: this.$t(
                                    'StatisticsCard.contractsTab.averageLabel.xaxisLabel'
                                ),
                                style: {
                                    fontSize: '20px',
                                    fontWeight: 600,
                                    cssClass: 'chartTitle',
                                },
                            },
                            categories: [],
                        },
                        title: {
                            text: this.$t(
                                'StatisticsCard.contractsTab.chartLabel2'
                            ),
                            align: 'left',
                        },
                        dataLabels: {
                            enabled: false,
                        },
                        colors: ['#B09FFF', '#8D79F6'],
                        tooltip: {
                            enabled: true,
                            x: {
                                show: false,
                            },
                        },
                    },
                    tabs: [
                        {
                            label: 'Incl.',
                            label_lang: 'statistics.incl',
                            active: true,
                        },
                        {
                            label: 'Excl.',
                            label_lang: 'statistics.excl',
                            active: false,
                        },
                    ],
                },
                {
                    type: 'bar',
                    cardTitle:
                        'StatisticsCard.contractsTab.numberContractLabel.label',
                    xaxisLabel:
                        'StatisticsCard.contractsTab.numberContractLabel.xaxisLabel',
                    chartTitle: 'StatisticsCard.contractsTab.chartLabel3',
                    endpoint: 'contracts_per_contractlength',
                    favorite: false,
                    series: [
                        {
                            name: this.$t(
                                'StatisticsCard.contractsTab.numberContractLabel.xaxisLabel'
                            ),
                            data: [30, 40, 45, 50, 49, 60, 70, 91],
                        },
                    ],
                    options: {
                        chart: {
                            id: 'vuechart-example',
                            zoom: {
                                enabled: false,
                            },
                            toolbar: {
                                show: false,
                            },
                        },
                        xaxis: {
                            label: {
                                show: true,
                            },
                            title: {
                                text: this.$t(
                                    'StatisticsCard.contractsTab.numberContractLabel.xaxisLabel'
                                ),
                                style: {
                                    fontSize: '20px',
                                    fontWeight: 600,
                                    cssClass: 'chartTitle',
                                },
                            },
                            categories: [
                                1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998,
                            ],
                        },
                        title: {
                            text: this.$t(
                                'StatisticsCard.contractsTab.chartLabel3'
                            ),
                            align: 'left',
                        },
                        dataLabels: {
                            enabled: false,
                        },
                        tooltip: {
                            enabled: true,
                            x: {
                                show: false,
                            },
                        },
                    },
                },
                {
                    type: 'pie',
                    cardTitle:
                        'StatisticsCard.contractsTab.mostCommonLabel.label',
                    endpoint: 'most_common_invoice_interval',
                    favorite: true,
                    series: [],

                    options: {
                        chart: {
                            width: 400,
                            height: 300,
                        },

                        fill: {
                            opacity: 1,
                        },
                        stroke: {
                            width: 1,
                            colors: undefined,
                        },
                        yaxis: {
                            show: false,
                        },
                        xaxis: {
                            show: false,
                            height: 100
                        },
                        legend: {
                            position: 'bottom',
                            show: true,
                            itemMargin: {
                                horizontal: 15,
                                vertical: 15
                            },
                        },
                        plotOptions: {
                            pie: {
                                offsetX: 0,
                                offsetY: 30,
                                dataLabels: {
                                    offset: 35,
                                    minAngleToShowLabel: 5
                                },
                                donut: {
                                    size: '65%',
                                    labels: {
                                        show: true
                                    }
                                }
                            },
                        },
                        dataLabels: {
                            enabled: true,
                            toggleDataSeries: true,
                            minAngleToShowLabel: 0,
                            offsetY: 20,
                            offsetX: 30,
                            style: {
                                fontWeight: 'light',
                                colors: ['#475569'],
                                fontSize: "12px"
                            },
                        },
                        tooltip: {
                            enabled: true,
                            x: {
                                show: false,
                            },
                            y: {
                                formatter: (seriesName, checkData) => {
                                    let dt = this.commonIntervalList[checkData.dataPointIndex]
                                    return `${dt.amount_of_contracts} contracts <br/>  ${dt.percentage.toFixed(2)}%`;
                                },
                                title: {
                                    formatter: (seriesName) => '',
                                },
                            },
                        },
                    },
                },
            ],

            invoicesChart: [
                {
                    type: 'area',
                    // title: 'StatisticsCard.invoicesTab.amountBillLabel',
                    cardTitle:
                        'StatisticsCard.invoicesTab.amountBillLabel',
                    // chartLabel:
                    //     'StatisticsCard.customerTab.highestLabel.chartLabel',
                    // xaxisLabel:
                    //     'StatisticsCard.customerTab.highestLabel.xaxisLabel',

                    favorite: false,
                    selectOptions: true,
                    chooseOption: {
                        label: 'StatisticsCard.invoicesTab.annually',
                        value: 'annual',
                    },
                    allOptions: [
                        {
                            label: 'StatisticsCard.invoicesTab.annually',
                            objectLang: 'StatisticsCard.invoicesTab.annually',
                            value: 'annual',
                        },
                        {
                            label: 'StatisticsCard.invoicesTab.monthly',
                            objectLang: 'StatisticsCard.invoicesTab.monthly',
                            value: 'monthly',
                        },
                        {
                            label: 'StatisticsCard.invoicesTab.quarterly',
                            objectLang: 'StatisticsCard.invoicesTab.quarterly',
                            value: 'quarterly',
                        },
                        {
                            label: 'StatisticsCard.invoicesTab.semiAnnually',
                            objectLang: 'StatisticsCard.invoicesTab.semiAnnually',
                            value: 'semi_annual',
                        },
                    ],
                    series: [],
                    options: {
                        chart: {
                            id: 'vuechart-example',
                            zoom: {
                                enabled: false,
                            },
                            toolbar: {
                                show: false,
                            },
                        },
                        xaxis: {
                            label: {
                                show: true,
                            },
                            title: {
                                text: this.$t('StatisticsCard.invoicesTab.amountBillLabel.xaxisLabel'),
                                style: {
                                    fontSize: '20px',
                                    fontWeight: 600,
                                    cssClass: 'chartTitle',
                                },
                            },
                            categories: [
                                1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998,
                            ],
                        },
                        title: {
                            text: this.$t('dashboardCard.chartTitle'),
                            align: 'left',
                        },
                        dataLabels: {
                            enabled: false,
                        },
                        colors: ['#F2C94C'],
                        tooltip: {
                            enabled: true,
                            x: {
                                show: false,
                            },
                            y: {
                                formatter: (seriesName, checkData) => {
                                    return `${seriesName}`;
                                },
                            },
                        },
                    },
                    tabs: [
                        {
                            label: 'statistics.total',
                            active: true,
                            invoiceAmnt: 'total_invoices',
                        },
                        {
                            label: 'statistics.amount_excl',
                            active: false,
                            invoiceAmnt: 'total_amount_ex_vat',
                        },
                        {
                            label: 'statistics.amount_incl',
                            active: false,
                            invoiceAmnt: 'total_amount_inc_vat',
                        },
                    ]
                },
                {
                    type: 'area',
                    // title: 'StatisticsCard.invoicesTab.paymenReceivedLabel',
                    cardTitle:
                        'StatisticsCard.invoicesTab.paymenReceivedLabel',
                    favorite: false,
                    selectOptions: true,
                    chooseOption: {
                        label: 'StatisticsCard.invoicesTab.annually',
                        value: 'annual',
                    },
                    allOptions: [
                        {
                            label: 'StatisticsCard.invoicesTab.annually',
                            objectLang: 'StatisticsCard.invoicesTab.annually',
                            value: 'annual',
                        },
                        {
                            label: 'StatisticsCard.invoicesTab.monthly',
                            objectLang: 'StatisticsCard.invoicesTab.monthly',
                            value: 'monthly',
                        },
                        {
                            label: 'StatisticsCard.invoicesTab.quarterly',
                            objectLang: 'StatisticsCard.invoicesTab.quarterly',
                            value: 'quarterly',
                        },
                        {
                            label: 'StatisticsCard.invoicesTab.semiAnnually',
                            objectLang: 'StatisticsCard.invoicesTab.semiAnnually',
                            value: 'semi_annual',
                        },
                    ],
                    series: [],
                    options: {
                        chart: {
                            id: 'vuechart-example',
                            zoom: {
                                enabled: false,
                            },
                            toolbar: {
                                show: false,
                            },
                        },
                        xaxis: {
                            label: {
                                show: true,
                            },
                            title: {
                                text: this.$t('StatisticsCard.invoicesTab.paymenReceivedLabel.xaxisLabel'),
                                style: {
                                    fontSize: '20px',
                                    fontWeight: 600,
                                    cssClass: 'chartTitle',
                                },
                            },
                            categories: [
                                1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998,
                            ],
                        },
                        title: {
                            text: this.$t('dashboardCard.chartTitle'),
                            align: 'left',
                        },
                        dataLabels: {
                            enabled: false,
                        },
                        colors: ['#33ACF2'],
                        tooltip: {
                            enabled: true,
                            x: {
                                show: false,
                            },
                        },
                    },
                    tabs: [
                        {
                            label: 'statistics.total',
                            active: true,
                            invoiceAmnt: 'total_invoices',
                        },
                        {
                            label: 'statistics.amount',
                            active: false,
                            invoiceAmnt: 'total_amount_inc_vat',
                        },
                    ]
                },
                {
                    type: 'bar',
                    // title: 'StatisticsCard.invoicesTab.lostRevenueLabel',
                    cardTitle:
                        'StatisticsCard.invoicesTab.lostRevenueLabel',
                    favorite: false,
                    // chooseOption: {
                    //     label: 'StatisticsCard.invoicesTab.balance',
                    //     value: 'balance',
                    // },
                    series: [],

                    options: {
                        chart: {
                            id: 'vuechart-example',
                            zoom: {
                                enabled: false,
                            },
                            toolbar: {
                                show: false,
                            },
                        },
                        xaxis: {
                            label: {
                                show: true,
                            },
                            title: {
                                text: this.$t('StatisticsCard.invoicesTab.lostRevenueLabel.xaxisLabel'),
                                style: {
                                    fontSize: '20px',
                                    fontWeight: 600,
                                    cssClass: 'chartTitle',
                                },
                            },
                            categories: [
                                1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998,
                            ],
                        },
                        title: {
                            text: this.$t('dashboardCard.chartTitle'),
                            align: 'left',
                        },

                        dataLabels: {
                            enabled: false,
                            style: {
                                fontWeight: 'light',
                                colors: ['#475569'],
                            },
                        },
                        colors: ['#41D0AE'],
                        tooltip: {
                            enabled: true,
                            x: {
                                show: false,
                            },
                        },
                    },
                    tabs: [
                        {
                            label: 'statistics.balance',
                            active: true,
                            invoiceAmnt: 'balance',
                        },
                        {
                            label: 'statistics.total',
                            active: false,
                            invoiceAmnt: 'total_invoices',
                        },
                    ]
                },
                {
                    type: 'bar',
                    // title: 'StatisticsCard.invoicesTab.overDueUnpaidLabel',
                    cardTitle:
                        'StatisticsCard.invoicesTab.overDueUnpaidLabel',
                    favorite: false,
                    series: [],
                    options: {
                        chart: {
                            id: 'vuechart-example',
                            zoom: {
                                enabled: false,
                            },
                            toolbar: {
                                show: false,
                            },
                        },
                        xaxis: {
                            label: {
                                show: true,
                            },
                            title: {
                                text: this.$t('StatisticsCard.invoicesTab.overDueUnpaidLabel.xaxisLabel'),
                                style: {
                                    fontSize: '20px',
                                    fontWeight: 600,
                                    cssClass: 'chartTitle',
                                },
                            },
                            categories: [
                                1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998,
                            ],
                        },
                        plotOptions: {
                            bar: {
                                borderRadius: 4,
                                horizontal: true,
                            },
                        },
                        grid: {
                            yaxis: {
                                lines: {
                                    show: false,
                                },
                            },
                            xaxis: {
                                lines: {
                                    show: true,
                                },
                            },
                        },

                        title: {
                            text: this.$t('dashboardCard.chartTitle'),
                            align: 'left',
                        },
                        dataLabels: {
                            enabled: false,
                        },
                        colors: ['#FF914D'],
                        tooltip: {
                            enabled: true,
                            x: {
                                show: false,
                            },
                        },
                    },
                    tabs: [
                        {
                            label: 'statistics.invoices',
                            active: true,
                            invoiceAmnt: 'Total_invoices',
                        },
                        {
                            label: 'statistics.amount',
                            active: false,
                            invoiceAmnt: 'Total_invoice_amount',
                        },
                    ]
                },
            ],
            productChart: [
                {
                    type: 'bar',
                    // title: 'StatisticsCard.productTab.highestEarningLabel',
                    cardTitle:
                        'StatisticsCard.productTab.highestEarningLabel.chartTitle',
                    favorite: false,
                    selectOptions: true,
                    chooseOption: {
                        label: 'StatisticsCard.customerTab.highest',
                        value: 'highest',
                    },
                    allOptions: [
                        {
                            label: 'StatisticsCard.customerTab.highest',
                            objectLang: 'StatisticsCard.customerTab.highest',
                            value: 'highest',
                        },
                        {
                            label: 'StatisticsCard.customerTab.lowest',
                            objectLang: 'StatisticsCard.customerTab.lowest',
                            value: 'lowest',
                        },
                    ],
                    series: [],
                    options: {
                        chart: {
                            id: 'vuechart-example',
                            zoom: {
                                enabled: false,
                            },
                            toolbar: {
                                show: false,
                            },
                        },
                        xaxis: {
                            labels: {
                                show: true,

                            },
                            title: {
                                text: this.$t('StatisticsCard.productTab.highestEarningLabel.xaxisLabel'),
                                style: {
                                    fontSize: '20px',
                                    fontWeight: 600,
                                    cssClass: 'chartTitle',
                                },
                            },
                            categories: [],
                        },
                        title: {
                            text: this.$t('dashboardCard.chartTitle'),
                            align: 'left',
                        },
                        dataLabels: {
                            enabled: false,
                        },
                        colors: ['#8D79F6'],
                        tooltip: {
                            enabled: true,
                            x: {
                                show: false,
                            },
                            y: {
                                formatter: (value, data) => {
                                    if (data.series.length <= 0) {
                                        return ''
                                    }
                                    let output = value
                                    if (this.highestArticleList.length > 0) {
                                        let dt = this.highestArticleList[data.dataPointIndex]
                                        if (dt.description) {
                                            output = `${dt.description}: ${data.series[data.seriesIndex][data.dataPointIndex]}`
                                        }

                                    }
                                    return `${output}`
                                }
                            }
                        },
                    },
                    tabs: [
                        {
                            label: 'statistics.purchase_price',
                            active: true,
                            productPrice: 'purchase_price',
                        },
                        {
                            label: 'statistics.sales_price',
                            active: false,
                            productPrice: 'sales_price',
                        },
                        {
                            label: 'statistics.profit',
                            active: false,
                            productPrice: 'profit',
                        },
                    ]
                },
                {
                    type: 'area',
                    cardTitle: 'StatisticsCard.productTab.salesDropshipLabel.chartTitle',
                    favorite: true,
                    extra: true,
                    searchArticle: true,
                    selectOptions: true,
                    series: [],
                    chooseOption: {
                        label: "StatisticsCard.productTab.article",
                        value: "article",
                    },
                    selectOptions: false,
                    // allOptions: [
                    //     {
                    //         label: "StatisticsCard.productTab.article",
                    //         objectLang: "StatisticsCard.productTab.article",
                    //         value: "article",
                    //     },
                    //     {
                    //         label: "StatisticsCard.customerTab.segment",
                    //         objectLang: "StatisticsCard.customerTab.segment",
                    //         value: "segment",
                    //     },
                    // ],
                    options: {
                        chart: {
                            id: 'vuechart-example',
                            zoom: {
                                enabled: false,
                            },
                            toolbar: {
                                show: false,
                            },
                        },
                        xaxis: {
                            label: {
                                show: true,
                            },
                            title: {
                                text: this.$t('StatisticsCard.productTab.amountBillLabel.xaxisLabel'),
                                style: {
                                    fontSize: '20px',
                                    fontWeight: 600,
                                    cssClass: 'chartTitle',
                                },
                            },
                            categories: [],
                        },
                        title: {
                            text: this.$t('dashboardCard.chartTitle'),
                            align: 'left',
                        },
                        dataLabels: {
                            enabled: false,
                        },
                        colors: ['#33ACF2', '#8D79F6', '#475569'],
                        tooltip: {
                            enabled: true,
                            x: {
                                show: false,
                            },
                        },
                    },
                    tabs: [
                        {
                            label: 'statistics.total',
                            active: true,
                            salesAmount: 'total_invoices',
                        },
                        {
                            label: 'statistics.incl',
                            active: false,
                            salesAmount: 'total_amount_inc_vat',
                        },
                        {
                            label: 'statistics.excl',
                            active: false,
                            salesAmount: 'total_amount_ex_vat',
                        },
                    ],
                    optionText: '',
                    mode: "SALES",
                },
                {
                    type: 'polarArea',
                    // title: 'StatisticsCard.productTab.mostSoldLabel',
                    cardTitle: 'StatisticsCard.productTab.mostSoldLabel.chartTitle',
                    favorite: true,
                    selectOptions: true,
                    chooseOption: {
                        label: 'StatisticsCard.customerTab.total',
                        value: 'most',
                    },
                    allOptions: [
                        {
                            label: 'StatisticsCard.customerTab.most',
                            objectLang: 'StatisticsCard.customerTab.most',
                            value: 'most',
                        },
                        {
                            label: 'StatisticsCard.customerTab.least',
                            objectLang: 'StatisticsCard.customerTab.least',
                            value: 'least',
                        },
                    ],
                    series: [],
                    options: {
                        chart: {
                            // height: 300,
                        },
                        stroke: {
                            colors: ['#fff'],
                            width: 1,
                        },
                        labels: [
                            'Rose A',
                            'Rose B',
                        ],
                        fill: {
                            opacity: 1,
                        },
                        yaxis: {
                            show: false,
                        },
                        xaxis: {
                            show: false,
                        },
                        legend: {
                            position: 'bottom',
                        },
                        plotOptions: {
                            polarArea: {
                                rings: {
                                    strokeWidth: 0,
                                },
                                spokes: {
                                    strokeWidth: 0,
                                },
                            },
                        },
                        dataLabels: {
                            formatter(val, opts) {
                                const series = `${
                                    opts.w.globals.series[opts.seriesIndex]
                                } Avtal`;

                                return [series, val.toFixed(1) + '%'];
                            },
                            style: {
                                fontWeight: 'light',
                                colors: ['#475569'],
                            },
                        },
                        tooltip: {
                            enabled: true,
                            x: {
                                show: false,
                            },
                        },
                    },
                    tabs: [
                        {
                            label: 'statistics.qty',
                            active: true,
                            productPrice: 'delivered_quantity',
                        },
                        {
                            label: 'statistics.percentage',
                            active: false,
                            productPrice: 'percentage',
                        },
                    ]
                },
                {
                    type: 'bar',
                    cardTitle: 'StatisticsCard.productTab.bestBenefitLabel.chartTitle',
                    favorite: false,
                    selectOptions: true,
                    chooseOption: {
                        label: 'StatisticsCard.customerTab.highest',
                        value: 'highest',
                    },
                    allOptions: [
                        {
                            label: 'StatisticsCard.customerTab.highest',
                            objectLang: 'StatisticsCard.customerTab.highest',
                            value: 'highest',
                        },
                        {
                            label: 'StatisticsCard.customerTab.lowest',
                            objectLang: 'StatisticsCard.customerTab.lowest',
                            value: 'lowest',
                        },
                    ],
                    series: [],
                    options: {
                        chart: {
                            id: 'vuechart-example',
                            zoom: {
                                enabled: false,
                            },
                            toolbar: {
                                show: false,
                            },
                        },
                        xaxis: {
                            label: {
                                show: false,
                                formatter: (value, timestamp, opts) => {
                                    return `${value}`;
                                },
                            },
                            title: {
                                text: this.$t('StatisticsCard.productTab.bestBenefitLabel.xaxisLabel'),
                                style: {
                                    fontSize: '20px',
                                    fontWeight: 600,
                                    cssClass: 'chartTitle',
                                },
                            },
                            categories: [],
                        },
                        title: {
                            text: this.$t('StatisticsCard.productTab.bestBenefitLabel.chartLabel'),
                            align: 'left',
                        },
                        dataLabels: {
                            enabled: false,
                        },
                        colors: ['#8D79F6'],
                        tooltip: {
                            enabled: true,
                            x: {
                                show: false,
                            },
                            y: {
                                formatter: (value, data) => {
                                    if (data.series.length <= 0) {
                                        return ''
                                    }
                                    let output = value
                                    if (this.benefitCostRatio.length > 0) {
                                        let dt = this.benefitCostRatio[data.dataPointIndex]
                                        if (dt.description) {
                                            output = `${dt.description}: ${data.series[data.seriesIndex][data.dataPointIndex]}`
                                        }
                                    }
                                    return `${output}`
                                }
                            }
                        },
                    },
                    tabs: [
                        {
                            label: 'statistics.purchase_price',
                            active: true,
                            productPrice: 'purchase_price',
                        },
                        {
                            label: 'statistics.sales_price',
                            active: false,
                            productPrice: 'sales_price',
                        },
                        {
                            label: 'statistics.benefit',
                            active: false,
                            productPrice: 'benefit_cost',
                        }
                    ]
                },
            ],
            generalChart: [
                {
                    type: 'area',
                    cardTitle: 'StatisticsCard.generalTab.salesPrognosis.title',
                    subtitle:
                        'StatisticsCard.generalTab.salesPrognosis.subtitle',
                    favorite: false,
                    // chooseOption: {
                    //     label: 'StatisticsCard.customerTab.highest',
                    //     value: 'highest',
                    // },
                    series: [],
                    options: {
                        chart: {
                            id: 'vuechart-example',
                            zoom: {
                                enabled: false,
                            },
                            toolbar: {
                                show: false,
                            },
                        },
                        xaxis: {
                            label: {
                                show: true,
                            },
                            title: {
                                text: this.$t('Message.month'),
                                style: {
                                    fontSize: '20px',
                                    fontWeight: 600,
                                    cssClass: 'chartTitle',
                                },
                            },
                            categories: [
                                1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998,
                            ],
                        },
                        yaxis: {
                            labels: {
                                show: true,
                                // formatter: value => {
                                //     return value > 999
                                //         ? `${value / 10000}K`
                                //         : value;
                                // },
                            },
                        },
                        title: {
                            text: this.$t('StatisticsCard.generalTab.salesPrognosis.subtitle'),
                            align: 'left',
                        },
                        dataLabels: {
                            enabled: false,
                        },
                        colors: ['#FB4B4B', '#33ACF2'],
                        legend: {
                            show: false,
                        },
                        tooltip: {
                            enabled: true,
                            x: {
                                show: false,
                            },
                        },
                    },
                    tabs: [
                        {
                            label: 'statistics.incl',
                            active: true,
                            salesAmount: 'sales_inc_vat',
                        },
                        {
                            label: 'statistics.excl',
                            active: false,
                            salesAmount: 'sales_ex_vat',
                        },
                    ]
                },
                {
                    type: 'area',
                    cardTitle: 'StatisticsCard.generalTab.prognosisArticleLabel.title',
                    subtitle:
                        'StatisticsCard.generalTab.prognosisArticleLabel.subtitle',
                    favorite: false,
                    series: [],
                    extra: true,
                    searchArticle: true,
                    mode: 'ARTICLE',
                    options: {
                        chart: {
                            id: 'vuechart-example',
                            zoom: {
                                enabled: false,
                            },
                            toolbar: {
                                show: false,
                            },
                        },
                        xaxis: {
                            label: {
                                show: true,
                            },
                            title: {
                                text: this.$t('Message.month'),
                                style: {
                                    fontSize: '20px',
                                    fontWeight: 600,
                                    cssClass: 'chartTitle',
                                },
                            },
                            categories: [
                                1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998,
                            ],
                        },
                        title: {
                            text: this.$t('StatisticsCard.generalTab.prognosisArticleLabel.subtitle'),
                            align: 'left',
                        },
                        dataLabels: {
                            enabled: false,
                        },
                        colors: ['#FFC700', '#4ED964'],
                        legend: {
                            show: false,
                        },
                        tooltip: {
                            enabled: true,
                            x: {
                                show: false,
                            },
                        },
                    },
                    tabs: [
                        {
                            label: 'statistics.incl',
                            active: true,
                            salesAmount: 'sales_inc_vat',
                        },
                        {
                            label: 'statistics.excl',
                            active: false,
                            salesAmount: 'sales_ex_vat',
                        },
                    ]
                },
                {
                    type: 'area',
                    cardTitle: 'StatisticsCard.generalTab.salesPrognosisLabel.title',
                    subtitle:
                        'StatisticsCard.generalTab.salesPrognosisLabel.subtitle',
                    favorite: false,
                    searchCustomer: true,
                    extra: true,
                    mode: 'CUSTOMER',
                    series: [],
                    options: {
                        chart: {
                            id: 'vuechart-example',
                            zoom: {
                                enabled: false,
                            },
                            toolbar: {
                                show: false,
                            },
                        },
                        xaxis: {
                            label: {
                                show: true,
                            },
                            title: {
                                text: this.$t('Message.month'),
                                style: {
                                    fontSize: '20px',
                                    fontWeight: 600,
                                    cssClass: 'chartTitle',
                                },
                            },
                            categories: [],
                        },
                        title: {
                            text: this.$t('StatisticsCard.generalTab.salesPrognosisLabel.subtitle'),
                            align: 'left',
                        },
                        dataLabels: {
                            enabled: false,
                        },
                        legend: {
                            show: false,
                        },
                        colors: ['#5855D6', '#5CE1E6'],
                        tooltip: {
                            enabled: true,
                            x: {
                                show: false,
                            },
                        },
                    },
                    tabs: [
                        {
                            label: 'statistics.incl',
                            active: true,
                            salesAmount: 'sales_inc_vat',
                        },
                        {
                            label: 'statistics.excl',
                            active: false,
                            salesAmount: 'sales_ex_vat',
                        },
                    ]
                },
            ],
        };
    },

    watch: {
        getLang() {
            // this.customersChart.forEach(chart => {
            //     chart.options = {
            //         xaxis: {
            //             title: {
            //                 text: this.$t('Message.month'),
            //                 style: {
            //                     fontSize: '20px',
            //                     fontWeight: 600,
            //                     cssClass: 'chartTitle',
            //                 },
            //             },
            //         },
            //         title: {
            //             text: this.$t('dashboardCard.chartTitle'),
            //             align: 'left',
            //         },
            //     };
            // });
        },
    },

    computed: {
        messageInfo() {
            if (this.getLang === '') {
                return this.swedishLang ? 'swe_info' : 'eng_info';
            } else return this.getLang === 'sv' ? 'swe_info' : 'eng_info';
        },
        getLang() {
            return this.config.lang.value;
        },
    },

    methods: {
        getContractsChart() {
            this.contractsChart.forEach(chart => {
                this.$useJwt.getContractsChart(chart.endpoint).then(res => {

                });
            });
        },
        // Invoices
        async getInvoiceBilled() {
            return this.$useJwt.getStatistics({URL: '/invoices/billed', method: 'get'})
        },

        async paymentReceived() {
            return this.$useJwt.getStatistics({URL: '/invoices/received_payments', method: 'get'})
        },

        async lostRevenue() {
            return this.$useJwt.getStatistics({URL: '/invoices/lost_revenue', method: 'get'})
        },

        async unPaidOverdue() {
            return this.$useJwt.getStatistics({URL: '/invoices/unpaid_overdue', method: 'get'})
        },

        // Articles
        async articleHighestLowest() {
            return this.$useJwt.getStatistics({URL: '/articles/highest_lowest_earning?i=1', method: 'get'})
        },

        async articleHistoricSales(searchData) {
            return this.$useJwt.getStatistics({URL: '/articles/historic_sales', method: 'get'}, {params: searchData})
        },
        async articleHistorySales(searchData) {
            return this.$useJwt.getStatistics({URL: `/articles/historic_sales?${searchData}`, method: 'get'})
        },

        async articleMostLeastSold() {
            return this.$useJwt.getStatistics({URL: '/articles/most_least_sold', method: 'get'})
        },

        async articleBenefitRatio() {
            return this.$useJwt.getStatistics({URL: '/articles/benefit_cost_ratio', method: 'get'})
        },

        // General
        async salesPrognosis() {
            return this.$useJwt.getStatistics({URL: '/general/sales_prognosis', method: 'get'})
        },

        async salesPrognosisArticle(searchData) {
            return this.$useJwt.getStatistics({URL: '/general/sales_prognosis', method: 'get'}, {params: searchData})
        },

        async salesPrognosisCustomer(searchData) {
            return this.$useJwt.getStatistics({
                URL: '/general/sales_prognosis_customer',
                method: 'get'
            }, {params: searchData})
        },


        init() {
            // this.$useJwt
            //     .getContractsChart('created_last_12_months')
            //     .then(res => {
            //         const {data} = res.data;
            //         this.contractsChart[0].series = [
            //             {
            //                 name: this.$t(
            //                     'StatisticsCard.contractsTab.chartLabel1'
            //                 ),
            //                 data: Object.keys(data).map(item => {
            //                     return data[item];
            //                 }),
            //             },
            //         ];
            //         this.contractsChart[0].options = {
            //             xaxis: {
            //                 categories: Object.keys(data).map(item =>
            //                     item.substring(0, 3)
            //                 ),
            //             },
            //         };
            //     });
        },
    },

    mounted() {
        this.init();
    },
};
