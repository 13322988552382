<template>
  <b-row class="mt-5">
    <b-col
        v-for="(chart, chartIndex) in customersChart"
        :key="chartIndex"
        cols="12"
        lg="6"
    >
      <b-card>
        <div class="w-full  bg-white">
          <div class=" mb-[15px] block lg:flex">
            <div class="w-full flex gap-3" :class="{'lg:w-[60%]': chart.mode === 'SALES'}">
              <div class="w-[5%]" v-if="chart.selectOptions">
                <div

                    class=""
                >
                  <b-navbar-nav class="nav align-items-center">
                    <b-nav-item-dropdown
                        no-caret
                        id="dropdown-grouped"
                        variant="link"
                        class="dropdown-language p-0"
                    >
                      <template class="p-0" #button-content>
                        <img src="@/assets/icon/chevronDown.png" alt="dropdown" class="w-[15px]"/>
                      </template>

                      <b-dropdown-item
                          v-for="(label, index) in chart.allOptions"
                          :key="index"
                          @click="switchOption(chartIndex, label, chart.extra)"
                      >
                        <span class="ml-50" v-text="$t(label.label)"/>
                      </b-dropdown-item>
                    </b-nav-item-dropdown>
                  </b-navbar-nav>
                </div>
              </div>
              <div class="w-[95%]">
                <p
                    v-if="chart.selectOptions"
                    class="mb-0 text-[15px] font-semibold"
                    v-text="
                          $t(chart.cardTitle, {
                            option: $t(chart.chooseOption.label),
                          })
                        "
                />
                <p
                    v-else
                    class="mb-0 text-[15px] font-semibold"
                    v-text="
                          $t(chart.cardTitle, {
                            option: chart.optionText ? chart.optionText : '',
                          })
                        "
                />
              </div>
            </div>
            <div class="w-full pt-[10px] lg:pt-[0px] lg:w-[40%]"
                 v-if="chart.hasOwnProperty('chooseOption') && chart.mode === 'SALES'">
              <div class="h-full">
                <div v-if="chart.hasOwnProperty('chooseOption') && chart.mode === 'SALES'"
                     class="flex justify-end">
                  <v-select v-if="chart.chooseOption.value === 'customer'"
                            :placeholder="$t('StatisticsCard.customerTab.customer')"
                            :options="customerList"
                            v-model="customerData.customer"
                            @search="searchCustomers"
                            @option:selected="setCustomer($event)"
                            class="resource-selector customer-chart w-full h-[30px]"
                            label="name"
                  />
                  <v-select v-if="chart.chooseOption.value === 'segment'"
                            :placeholder="$t('StatisticsCard.customerTab.segment')"
                            :options="segmentList"
                            v-model="customerData.segment"
                            @search="searchSegment"
                            @option:selected="setSegment($event)"
                            label="name"
                            class="resource-selector customer-chart w-full h-[30px]"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="w-full py-[10px] flex">
            <div class="w-[90%] flex justify-center">
              <div
                  v-if="chart.hasOwnProperty('tabs')"
                  class="bg-secondaryColor d-flex bigGap align-items-center rounded-[5px] px-[10px] py-[6px]"
                  :style="
                        chart.tabs && chart.tabs.length < 3
                          ? ''
                          : ''
                      "
              >
                      <span v-for="(data, index) in chart.tabs" :key="index">
                        <p
                            v-if="data.active"
                            class="bg-[#f6f6f6] mb-[0px] border-[1px] px-[15px] rounded-[2px] border-[#f6f6f6]"
                            v-text="$t(data.label)"
                        />

                        <p
                            v-else
                            @click="switchTabs(chartIndex, index, chart.extra)"
                            class="mb-0 text-light px-[15px]"
                            style="font-size: 15px"
                            role="button"
                            v-text="$t(data.label)"
                        />
                      </span>
              </div>
            </div>
            <div class="w-[10%] flex justify-end">
              <span
                  v-b-tooltip="chart.info ? chart.info[messageInfo] : ''"
              >
                      <mdicon
                          role="button"
                          :width="20"
                          :height="20"
                          name="help-circle-outline"
                      />
                    </span>
            </div>
          </div>
        </div>
        <b-card-title class="hidden">
          <span class="">
            <b-navbar>
              <div
                  v-if="chart.selectOptions"
                  class="d-flex align-items-center position-absolute -mt-16"
              >
                <b-navbar-nav class="nav align-items-center">
                  <b-nav-item-dropdown
                      no-caret
                      id="dropdown-grouped"
                      variant="link"
                      class="dropdown-language p-0"
                  >
                    <template class="p-0" #button-content>
                      <img src="@/assets/icon/chevronDown.png" alt="dropdown" class="w-[15px]"/>
                    </template>

                    <b-dropdown-item
                        v-for="(label, index) in chart.allOptions"
                        :key="index"
                        @click="switchOption(chartIndex, label, chart.extra)"
                    >
                      <span class="ml-50" v-text="$t(label.label)"/>
                    </b-dropdown-item>
                  </b-nav-item-dropdown>
                </b-navbar-nav>
              </div>
              <b-container class="flex flex-col">
                <b-row class="w-full">
                  <!-- :align-h="
                                        chart.hasOwnProperty('tabs')
                                            ? 'center'
                                            : 'between'
                                    " -->
                  <b-col
                      class="mb-2"
                      cols="12"
                      :class="{ left35: chart.selectOptions }"
                  >
                    <div class="d-flex smallGap w-full">
                      <p
                          v-if="chart.selectOptions"
                          class="mb-0 text-[15px]"
                          v-text="
                          $t(chart.cardTitle, {
                            option: $t(chart.chooseOption.label),
                          })
                        "
                      />
                      <p
                          v-else
                          class="mb-0 text-[15px]"
                          v-text="
                          $t(chart.cardTitle, {
                            option: chart.optionText ? chart.optionText : '',
                          })
                        "
                      />
                     <div v-if="chart">
                       <span v-if="chart.hasOwnProperty('chooseOption') && chart.mode === 'SALES'"
                             class="flex justify-content-end position-relative">
                           <v-select v-if="chart.chooseOption.value === 'customer'"
                                     :placeholder="$t('StatisticsCard.customerTab.customer')"
                                     :options="customerList"
                                     v-model="customerData.customer"
                                     @search="searchCustomers"
                                     @option:selected="setCustomer($event)"
                                     class="resource-selector select-size-sm d-inline-block w-full select-width sales__input"
                                     label="name"
                           />
                           <v-select v-if="chart.chooseOption.value === 'segment'"
                                     :placeholder="$t('StatisticsCard.customerTab.segment')"
                                     :options="segmentList"
                                     v-model="customerData.segment"
                                     @search="searchSegment"
                                     @option:selected="setSegment($event)"
                                     label="name"
                                     class="resource-selector select-size-sm d-inline-block w-full select-width sales__input"
                           />
                       </span>
                     </div>
                    </div>
                  </b-col>
                </b-row>

                <b-row class="w-full">
                  <b-col class="w-full flex justify-center" :cols="9">
                    <div
                        v-if="chart.hasOwnProperty('tabs')"
                        class="bg-secondaryColor d-flex bigGap align-items-center tabWrapperSmall"
                        :style="
                        chart.tabs && chart.tabs.length < 3
                          ? 'width: 120px'
                          : ''
                      "
                    >
                      <span v-for="(data, index) in chart.tabs" :key="index">
                        <b-button
                            v-if="data.active"
                            size="sm"
                            variant="light"
                            v-text="data.label"
                        />

                        <p
                            v-else
                            @click="switchTabs(chartIndex, index, chart.extra)"
                            class="mb-0 text-light"
                            style="font-size: 15px"
                            role="button"
                            v-text="data.label"
                        />
                      </span>
                    </div>
                  </b-col>

                  <b-col class="flex gap-1 items-center" cols="3">
                    <span
                        v-b-tooltip="chart.info ? chart.info[messageInfo] : ''"
                    >
                      <mdicon
                          role="button"
                          :width="20"
                          :height="20"
                          name="help-circle-outline"
                      />
                    </span>
                    <!--                    <small-->
                    <!--                        @click="openModalForArticles(chart.mode)"-->
                    <!--                        v-if="chart.searchArticle"-->
                    <!--                        class="cursor-pointer text-theme-sidebar font-semibold"-->
                    <!--                    >Sök</small-->
                    <!--                    >-->
                    <!--                    <img v-img="chart" height="20"/>-->
                  </b-col>
                </b-row>
              </b-container>
            </b-navbar>
          </span>

          <!-- <span class="d-block d-lg-none">
                        <div
                            class="w-100 align-items-center d-flex justify-content-between"
                        >
                            <div class="d-flex smallGap">
                                <p class="mb-0" v-text="$t(chart.title)" />
                            </div>

                            <div class="d-flex justify-content-center">
                                <div
                                    class="bg-secondaryColor d-flex bigGap align-items-center tabWrapperSmall"
                                >
                                    <span
                                        v-for="(data, index) in chart.tabs"
                                        :key="index"
                                    >
                                        <b-button
                                            v-if="data.active"
                                            size="sm"
                                            variant="light"
                                            v-text="data.label"
                                        />

                                        <p
                                            v-else
                                            @click="
                                                switchTabs(chartIndex, index)
                                            "
                                            class="mb-0 text-light"
                                            style="font-size: 15px"
                                            role="button"
                                            v-text="data.label"
                                        />
                                    </span>
                                </div>
                            </div>

                            <div class="d-flex align-items-center smallGap">
                                <mdicon
                                    role="button"
                                    :width="20"
                                    :height="20"
                                    name="help-circle-outline"
                                />
                                <img v-img="chart" height="20" />
                            </div>
                        </div>
                    </span> -->
        </b-card-title>

        <b-card-text>
          <apexchart
              :type="chart.type"
              :options="chart.options"
              :series="chart.series"
              height="300px"
          />
        </b-card-text>
      </b-card>
    </b-col>

    <CustomNoHeaderModalVue
        v-if="openModal"
        width="w-1/2 md:w-1/4"
        :title="''"
        @handleClose="openModal = false"
        :close="!true"
    >
      <div class="flex flex-col" slot="header">
        <div
            class="flex flex-col md:flex-row md:justify-between md:items-center gap-5 border-b p-1"
        >
          <div class="flex flex-col">
            <h2
                class="text-xl md:text-2xl font-semibold font-poppins text-gray-900"
            >
              Sök
            </h2>
          </div>
        </div>
      </div>
      <div class="flex flex-col" slot="body">
        <div class="mb-1">
          <div class="flex flex-col h-60 mt-2">
            <div>
              <b-form-group>
                <b-form-radio
                    v-model="searchArticleForm.radio"
                    name="radio-1"
                    value="ARTICLE"
                >
                  <span>Articles</span>
                </b-form-radio>
                <b-form-radio
                    name="radio-1"
                    v-model="searchArticleForm.radio"
                    value="ARTICLE_SEGMENT"
                >
                  <span>Article Segment</span>
                </b-form-radio>
              </b-form-group>
              <b-form-group>
                <v-select
                    class="w-full"
                    @option:selected="setData($event)"
                    :filterable="false"
                    :options="articleResults"
                    @search="searchData"
                >
                  <template slot="no-options"> Sök...</template>
                  <template slot="option" slot-scope="option">
                    <div class="d-center">
                      {{ option.label }}
                    </div>
                  </template>
                  <template slot="selected-option" slot-scope="option">
                    <div class="selected d-center">
                      {{ option.label }}
                    </div>
                  </template>
                </v-select>
              </b-form-group>
              <div class="flex">
                <button
                    @click="performGraphSearch"
                    :disabled="processing"
                    type="submit"
                    class="flex w-1/2 c-p-2 text-center focus:outline-none bg-theme-teal text-white whitespace-nowrap rounded-md"
                >
                  <span class="w-full text-center" v-if="processing">
                    <b-spinner small type="grow"/>
                    {{ $t("Message.loading") }}...
                  </span>

                  <span
                      class="whitespace-nowrap text-center w-full text-base"
                      v-else
                      v-text="'Sök'"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CustomNoHeaderModalVue>
  </b-row>
</template>

<script>
import {dummyChartMixins} from "./dummyChartMixins";
import CustomNoHeaderModalVue from "@/@core/customComponent/CustomNoHeaderModal.vue";
import debounce from "lodash.debounce";
import vSelect from "vue-select";

import {customersmixins} from "./customersmixins";

export default {
  mixins: [dummyChartMixins, customersmixins],
  name: "customers",
  components: {
    CustomNoHeaderModalVue,
    vSelect,
  },
  data() {
    return {
      articleResults: [],
      openModal: false,
      searchArticleForm: {
        value: "",
        radio: "ARTICLE",
      },
      processing: false,
      searchArticlePerform: false,
      customersChart: [
        {
          type: "bar",
          cardTitle: "StatisticsCard.customerTab.highestLabel.chartTitle",
          chartLabel: "StatisticsCard.customerTab.highestLabel.chartLabel",
          xaxisLabel: "StatisticsCard.customerTab.highestLabel.xaxisLabel",
          subtitle: "",
          favorite: false,
          selectOptions: true,
          chooseOption: {
            label: "StatisticsCard.customerTab.highest",
            value: "highest",
          },

          allOptions: [
            {
              label: "StatisticsCard.customerTab.highest",
              objectLang: "StatisticsCard.customerTab.highest",
              value: "highest",
            },
            {
              label: "StatisticsCard.customerTab.lowest",
              objectLang: "StatisticsCard.customerTab.lowest",
              value: "lowest",
            },
          ],
          series: [],
          options: {
            chart: {
              id: "vuechart-example",
              zoom: {
                enabled: false,
              },
              toolbar: {
                show: false,
              },
            },
            xaxis: {
              labels: {
                show: true,
              },

              title: {
                text: this.$t(
                    "StatisticsCard.customerTab.highestLabel.xaxisLabel"
                ),
                style: {
                  fontSize: "20px",
                  fontWeight: 600,
                  cssClass: "chartTitle",
                },
              },
              // categories: [],
            },
            yaxis: {
              labels: {
                show: true,
                formatter: (value) => {
                  return value > 999 ? value.toFixed(2) : value;
                },
              },
            },
            title: {
              text: this.$t(
                  "StatisticsCard.customerTab.highestLabel.chartLabel"
              ),
              align: "left",
            },
            dataLabels: {
              enabled: false,
            },
            colors: ["#B09FFF", "#8D79F6"],
            tooltip: {
              enabled: true,
              x: {
                show: false,
              },
              y: {
                formatter: (seriesName, opts) => {
                  return opts.w.globals.series[0][opts.dataPointIndex];
                },
              },
            },
          },
          tabs: [
            {
              label: "statistics.incl",
              active: true,
              monthlyVat: "total_monthly_average_inc_vat",
            },
            {
              label: "statistics.excl",
              active: false,
              monthlyVat: "total_monthly_average_ex_vat",
            },
          ],
          mode: "BILLED",
          info: ''
        },
        {
          type: "area",
          cardTitle: "StatisticsCard.customerTab.salesSegmentLabel.chartTitle",
          chartLabel: "StatisticsCard.customerTab.salesSegmentLabel.chartLabel",
          xaxisLabel: "StatisticsCard.customerTab.salesSegmentLabel.xaxisLabel",
          favorite: true,
          extra: true,
          searchArticle: true,
          selectOptions: true,
          series: [],
          options: {
            chart: {
              id: "vuechart-example",
              zoom: {
                enabled: false,
              },
              toolbar: {
                show: false,
              },
            },
            xaxis: {
              label: {
                show: true,
              },
              title: {
                text: this.$t(
                    "StatisticsCard.customerTab.salesSegmentLabel.xaxisLabel"
                ),
                style: {
                  fontSize: "20px",
                  fontWeight: 600,
                  cssClass: "chartTitle",
                },
              },
              categories: [],
            },
            title: {
              text: this.$t(
                  "StatisticsCard.customerTab.salesSegmentLabel.chartLabel"
              ),
              align: "left",
            },
            colors: ["#FF0000", "#8D79F6", "#475569"],
            fill: {
              type: "gradient",
              gradient: {
                opacityFrom: 0,
                opacityTo: 0,
              },
            },
            stroke: {
              curve: "smooth",
            },
            dataLabels: {
              enabled: false,
            },
            tooltip: {
              enabled: true,
              x: {
                show: false,
              },
            },
          },
          tabs: [
            {
              label: "statistics.total",
              active: true,
              salesAmount: "total_invoices",
            },
            {
              label: "statistics.incl",
              active: false,
              salesAmount: "total_amount_inc_vat",
            },
            {
              label: "statistics.excl",
              active: false,
              salesAmount: "total_amount_ex_vat",
            },
          ],
          chooseOption: {
            label: "StatisticsCard.customerTab.customer",
            objectLang: "StatisticsCard.customerTab.customer",
            value: "customer",
          },
          selectOptions: false,
          // allOptions: [
          //   {
          //     label: "StatisticsCard.customerTab.customer",
          //     objectLang: "StatisticsCard.customerTab.customer",
          //     value: "customer",
          //   },
            // {
            //   label: "StatisticsCard.customerTab.segment",
            //   objectLang: "StatisticsCard.customerTab.segment",
            //   value: "segment",
            // },
          // ],
          optionText: "",
          mode: "SALES",
          info: ''
        },
        {
          type: "bar",
          cardTitle: "StatisticsCard.customerTab.latePayingLabel.chartTitle",
          chartLabel: "StatisticsCard.customerTab.latePayingLabel.chartLabel",
          xaxisLabel: "StatisticsCard.customerTab.latePayingLabel.xaxisLabel",
          selectOptions: true,
          favorite: false,
          mode: "LATE_PAYING_CUSTOMER",
          chooseOption: {
            label: "StatisticsCard.customerTab.total",
            value: "Most",
          },
          allOptions: [
            {
              label: "StatisticsCard.customerTab.most",
              objectLang: "StatisticsCard.customerTab.most",
              value: "Most",
            },
            {
              label: "StatisticsCard.customerTab.least",
              objectLang: "StatisticsCard.customerTab.least",
              value: "Least",
            },
          ],
          series: [
            {
              name: this.$t(
                  "StatisticsCard.customerTab.latePayingLabel.chartLabel"
              ),
              data: [],
            },
          ],
          options: {
            chart: {
              id: "vuechart-example",
              zoom: {
                enabled: false,
              },
              toolbar: {
                show: false,
              },
            },
            xaxis: {
              label: {
                show: true,
              },
              title: {
                text: this.$t(
                    "StatisticsCard.customerTab.latePayingLabel.xaxisLabel"
                ),

                style: {
                  fontSize: "20px",
                  fontWeight: 600,
                  cssClass: "chartTitle",
                },
              },
              categories: [],
            },
            yaxis: {
              labels: {
                show: true,
                formatter: (value) => {
                  return value > 999 ? value.toFixed(2) : value;
                },
              },
            },

            title: {
              text: this.$t(
                  "StatisticsCard.customerTab.latePayingLabel.chartLabel"
              ),
              align: "left",
            },
            dataLabels: {
              enabled: false,
            },
            colors: ["#FBCE30"],
            tooltip: {
              enabled: true,
              x: {
                show: false,
              },
            },
          },
          tabs: [
            {
              label: "statistics.total",
              active: true,
              moneyPay: "total",
            },
            {
              label: "statistics.net",
              active: false,
              moneyPay: "net",
            },
            {
              label: "statistics.invoices",
              active: false,
              moneyPay: "total_invoices",
            },
          ],
          info: ''
        },
        {
          type: "bar",
          cardTitle: "StatisticsCard.customerTab.bestBenefitLabel.chartTitle",
          chartLabel: "StatisticsCard.customerTab.bestBenefitLabel.chartLabel",
          xaxisLabel: "StatisticsCard.customerTab.bestBenefitLabel.xaxisLabel",
          selectOptions: true,
          favorite: false,

          chooseOption: {
            label: "StatisticsCard.customerTab.best",
            value: "best",
          },
          allOptions: [
            {
              label: "StatisticsCard.customerTab.best",
              objectLang: "StatisticsCard.customerTab.best",
              value: "best",
            },
            {
              label: "StatisticsCard.customerTab.worst",
              objectLang: "StatisticsCard.customerTab.worst",
              value: "worst",
            },
          ],
          series: [
            {
              name: this.$t(
                  "StatisticsCard.customerTab.bestBenefitLabel.chartLabel"
              ),
              data: [],
            },
          ],
          options: {
            chart: {
              id: "vuechart-example",
              zoom: {
                enabled: false,
              },
              toolbar: {
                show: false,
              },
            },
            xaxis: {
              type: 'category',
              labels: {
                show: true,
                formatter: (seriesName, checkData) => {
                  return `${seriesName}`;
                },
              },
              title: {
                text: this.$t(
                    "StatisticsCard.customerTab.bestBenefitLabel.xaxisLabel"
                ),
                style: {
                  fontSize: "20px",
                  fontWeight: 600,
                  cssClass: "chartTitle",
                },
              },

              categories: [],
            },
            yaxis: {
              labels: {
                show: true,
                formatter: (value) => {
                  return value > 999 ? `${value.toFixed(2)}` : value;
                },
              },
            },
            title: {
              text: this.$t(
                  "StatisticsCard.customerTab.bestBenefitLabel.chartLabel"
              ),
              align: "left",
            },

            dataLabels: {
              enabled: false,
            },
            colors: ["#41D0AEA8"],
            tooltip: {
              enabled: true,
              x: {
                show: false,
              },
              y: {
                formatter: (seriesName, checkData, opts) => {
                  if (checkData.series.length <= 0) {
                    return ''
                  }
                  let output = seriesName
                  if (this.customerBenefitData.length > 0) {
                    let dt = this.customerBenefitData[checkData.dataPointIndex]
                    if (dt.customer_name) {
                      output = `${dt.customer_name}: ${checkData.series[checkData.seriesIndex][checkData.dataPointIndex]}`
                    }

                  }
                  return `${output}`;
                },
              },
            },
          },
          mode: "BENEFIT",
          info: ''
        },
        {
          type: "bar",
          cardTitle:
              "StatisticsCard.customerTab.historyLatePayingLabel.chartTitle",
          chartLabel:
              "StatisticsCard.customerTab.historyLatePayingLabel.chartLabel",
          xaxisLabel:
              "StatisticsCard.customerTab.historyLatePayingLabel.xaxisLabel",
          favorite: false,
          selectOptions: false,
          chooseOption: {
            label: "StatisticsCard.customerTab.total",
            value: "Avg.",
          },
          allOptions: [
            {
              label: "StatisticsCard.customerTab.most",
              objectLang: "StatisticsCard.customerTab.most",
              value: "Most",
            },
            {
              label: "StatisticsCard.customerTab.least",
              objectLang: "StatisticsCard.customerTab.least",
              value: "Least",
            },
          ],
          series: [
            {
              name: this.$t(
                  "StatisticsCard.customerTab.historyLatePayingLabel.chartLabel"
              ),
              data: [],
            },
          ],
          options: {
            chart: {
              id: "vuechart-example",
              zoom: {
                enabled: false,
              },
              toolbar: {
                show: false,
              },
            },
            xaxis: {
              label: {
                show: true,
              },
              title: {
                text: this.$t(
                    "StatisticsCard.customerTab.historyLatePayingLabel.xaxisLabel"
                ),

                style: {
                  fontSize: "20px",
                  fontWeight: 600,
                  cssClass: "chartTitle",
                },
              },
              categories: [],
            },
            yaxis: {
              labels: {
                show: true,
                formatter: (value) => {
                  return value > 999 ? value.toFixed(2) : value;
                },
              },
            },

            title: {
              text: this.$t(
                  "StatisticsCard.customerTab.historyLatePayingLabel.chartLabel"
              ),
              align: "left",
            },
            dataLabels: {
              enabled: false,
            },
            colors: ["#FBCE30"],
            tooltip: {
              enabled: true,
              x: {
                show: false,
              },
              y: {
                formatter: (seriesName, checkData) => {
                  return `${seriesName}`;
                },
              },
            },
          },
          mode: 'LATE',
          tabs: [
            {
              label: "statistics.average",
              active: true,
              monthlyVat: "average_lateness",
            },
            {
              label: "statistics.total",
              active: false,
              monthlyVat: "total_late_invoice",
            },
          ],
          info: ''
        },
      ],
      mode: "HISTORY",
      customerData: {
        customer: '',
        segment: '',
      },
      customerList: [],
      segmentList: [],
      averageLatePaying: [],
      customerSalesData: {},
      customerBenefitData: [],
      latePayingData: {}
    };
  },
  watch: {
    "searchArticleForm.radio": function (n, o) {
      if (n) {
        this.articleResults = [];
      }
    },
  },
  methods: {
    performGraphSearch() {
      this.processing = true;
      const payload = {};
      let mode = "";
      if (this.searchArticleForm.radio === "ARTICLE") {
        mode = "ARTICLE";
        payload["article_number"] = this.searchArticleForm.value;
      } else {
        mode = "SEGMENT";
        payload["segment_id"] = this.searchArticleForm.value;
      }
      this.getHistoricSales(payload, mode);
    },
    searchData(search, loading) {
      if (search.length) {
        loading(true);
        this.searchRemoteData(loading, search, this);
      }
    },
    searchRemoteData: debounce(function (loading, search, vm) {
      const URL =
          this.searchArticleForm.radio === "ARTICLE"
              ? "articles?search=" + escape(search)
              : "segments/articles?search=" + escape(search);

      this.$useJwt
          .useBaseURLOnly(URL)
          .then((res) => {
            if (this.searchArticleForm.radio === "ARTICLE") {
              this.articleResults = res.data.data.map((el) => {
                return {label: el.article_number, value: el.article_number};
              });
            } else {
              this.articleResults = res.data.results.map((el) => {
                return {label: el.name, value: el.id};
              });
            }
            loading(false);
          })
          .catch((err) => {
          });
    }, 350),
    setData(item) {
      this.searchArticleForm.value = item.value;
    },
    openModalForArticles(data) {
      this.articleSearch = true;
      this.openModal = true;
    },
    switchOption(chartIndex, value, extra) {
      let chart = this.customersChart[chartIndex]
      this.customersChart[chartIndex].chooseOption = value;
      if (chart.mode === "LATE_PAYING_CUSTOMER") {
        this.switchLatePayingCustomer()
      } else {
        // let currentTabs = this.customersChart[chartIndex].tabs.find(item => item.active == true);
        this.reconstructGraph(chartIndex, value.value, extra);
      }

    },
    switchTabs(chartIndex, index, extra = null) {
      let targetChart = this.customersChart[chartIndex]
      let curentTabs = this.customersChart[chartIndex].tabs;
      curentTabs.find((item) => item.active === true).active = false;
      curentTabs[index].active = true;
      if (targetChart.mode === "SALES") {
        this.switchSales(targetChart.chooseOption.value, curentTabs[index].salesAmount)
      } else if (targetChart.mode === "LATE") {
        this.switchAverageLatePayingCustomer(targetChart.chooseOption.value)

      } else if (targetChart.mode === "LATE_PAYING_CUSTOMER") {
        this.switchLatePayingCustomer()
      } else {
        this.reconstructGraph(chartIndex, null, extra);

      }

    },

    reconstructGraph(chartIndex, value = null, extra = false) {
      if (extra) {
        this.renderExtraGraph(chartIndex, value, extra);
      } else {
        if (this.customersChart[chartIndex].tabs) {
          let currentTabs = this.customersChart[chartIndex].tabs.find(
              (item) => item.active === true
          );

          let currentData = value
              ? this.customersChart[chartIndex][value][currentTabs.value]
              : this.customersChart[chartIndex][
                  this.customersChart[chartIndex].chooseOption.value
                  ][currentTabs.value];
          //   if (typeof this.customerSalesData === 'string') {
          //   this.customersChart[chartIndex].options = {
          //     xaxis: {
          //       categories: [],
          //     },
          //   };
          //   this.customersChart[chartIndex].series = [
          //     {
          //       name: this.$t(this.customersChart[chartIndex].chartLabel),
          //       data: salesSeries,
          //     },
          //   ];
          //   return;
          // }
          this.customersChart[chartIndex].series = [
            {
              name: this.$t(this.customersChart[chartIndex].chartLabel),
              data: currentData.map((item) => {
                return item[currentTabs.monthlyVat];
              }),
            },
          ];

          this.customersChart[chartIndex].options = {
            xaxis: {
              categories: currentData.map((item) => {
                return `${item.customer_number}  ${item.customer_name}`;
              }),
            },
          };
        } else {
          let currentData = this.customersChart[chartIndex][value];

          this.customersChart[chartIndex].series = [
            {
              name: this.$t(this.customersChart[chartIndex].chartLabel),
              data: currentData.map((item) => {
                return item[this.customersChart[chartIndex].lookup];
              }),
            },
          ];

          this.customersChart[chartIndex].options = {
            xaxis: {
              categories: currentData.map((item) => {
                return `${item.customer_number}  ${item.customer_name}`;
              }),
            },
          };
        }
      }
    },

    renderExtraGraph(chartIndex, value, extra) {
      if (this.customersChart[chartIndex].tabs) {
        let currentTabs = this.customersChart[chartIndex].tabs.find(
            (item) => item.active == true
        );

        if (
            this.customersChart[chartIndex].searchArticle &&
            !this.searchArticlePerform
        ) {
          return;
        }

        if (this.customersChart[chartIndex].unified) {
          let currentData = this.customersChart[chartIndex]["full"];

          if (Object.keys(currentData).length > 0) {
            Object.keys(currentData).map((el) => {
              let objectData = currentData[el];
              Object.keys(objectData).map((row) => {
                this.customersChart[chartIndex].options = {
                  xaxis: {
                    categories: Object.keys(objectData[row]).map((item) => {
                      return `${item}`;
                    }),
                  },
                };

                const obj = {};
                obj.name = this.$t(this.customersChart[chartIndex].chartLabel);
                obj.data = Object.keys(objectData[row]).map((item) => {
                  return `${objectData[row][item][currentTabs.salesAmount]}`;
                });
                this.customersChart[chartIndex].series.push(obj);
              });
            });
          }
        } else {
          let currentData = value
              ? this.customersChart[chartIndex][value]
              : this.customersChart[chartIndex][
                  this.customersChart[chartIndex].chooseOption.value
                  ];
          this.customersChart[chartIndex].series = [
            {
              name: this.$t(this.customersChart[chartIndex].chartLabel),
              data: currentData.map((item) => {
                return item[currentTabs.moneyPay];
              }),
            },
          ];

          this.customersChart[chartIndex].options = {
            xaxis: {
              categories: currentData.map((item) => {
                return `Num# ${item.customer_number}`;
              }),
            },
          };
        }
      }
    },

    getBillingCustomers(Index) {
      this.$useJwt.getCustomersChart("recurrently_billed").then((res) => {
        const {data} = res.data;
        let chartIndex = this.customersChart.findIndex(item => item.mode === 'BILLED')
        const info = {
          swe_info: res.data.swe_info,
          eng_info: res.data.eng_info,
        };
        const chartOption = this.customersChart[chartIndex].chooseOption.value;
        const vatActive = this.customersChart[chartIndex].tabs.find(
            (tab) => tab.active === true
        );
        Object.keys(data).forEach((item) => {
          this.customersChart[chartIndex][item] = data[item];
          //Update vatValue
          Object.keys(data[item]).forEach((vatValue, vatIndex) => {
            this.customersChart[chartIndex].tabs[vatIndex].value = vatValue;
          });
        });
        let currentData = this.customersChart[chartIndex][chartOption][vatActive.value];
        this.customersChart[chartIndex].choosed = chartOption;
        this.customersChart[chartIndex].series = [
          {
            name: this.$t(this.customersChart[chartIndex].chartLabel),
            data: currentData.map((item) => {
              return item[vatActive.monthlyVat];
            }),
          },
        ];
        this.customersChart[chartIndex].options = {
          xaxis: {
            categories: currentData.map((item) => {
              return `${item.customer_number}  ${item.customer_name}`;
            }),
          },
        };
        this.customersChart[chartIndex].info = info;
      });
    },

    async getHistoricSales(searchData, textMode) {
      const chartIndex = 1;
      const {data} = await this.articleHistoricSales(searchData);
      const arrayData = data.data;
      const info = {swe_info: data.swe_info, eng_info: data.eng_info};
      this.processing = false;
      this.openModal = false;

      const vatActive = this.customersChart[chartIndex].tabs.find(
          (tab) => tab.active === true
      );

      this.customersChart[chartIndex]["full"] = arrayData;
      let currentData = arrayData;

      this.customersChart[chartIndex].series = [];
      this.customersChart[chartIndex].optionText =
          textMode === "ARTICLE" ? "for `Article`" : "for `Article Segment`";

      // let searchKey = null

      // if (this.searchArticleForm.value === 'ARTICLE') {
      //     searchKey = 'article_number'
      // } else {
      //     searchKey = 'segment_id'
      // }

      if (Object.keys(currentData).length > 0) {
        Object.keys(currentData).map((el) => {
          let objectData = currentData[el];
          Object.keys(objectData).map((row) => {
            this.customersChart[chartIndex].options = {
              xaxis: {
                categories: Object.keys(objectData[row]).map((item) => {
                  return `${item}`;
                }),
              },
            };

            const obj = {};
            obj.name = this.$t(this.customersChart[chartIndex].chartLabel);
            obj.data = Object.keys(objectData[row]).map((item) => {
              return `${objectData[row][item][vatActive.salesAmount]}`;
            });
            this.customersChart[chartIndex].series.push(obj);
          });
        });
      }

      this.customersChart[chartIndex].extra = true;
      this.customersChart[chartIndex].unified = true;
      this.customersChart[chartIndex].info = info;

      this.searchArticlePerform = true;

      this.customersChart[chartIndex].info = info;
    },

    async getAverageLate(index) {
      // const chartIndex = index;
      let chartIndex = this.customersChart.findIndex(item => item.mode === 'LATE')

      const {data} = await this.getLatePaying(chartIndex);
      const info = {swe_info: data.swe_info, eng_info: data.eng_info};
      this.averageLatePaying = data.data
      this.customersChart[chartIndex].series = [
        {
          name: this.$t(this.customersChart[chartIndex].chartLabel),
          data: data.data.map((item) => {
            return item.average_lateness;
          }),
        },
      ];

      this.customersChart[chartIndex].options = {
        xaxis: {
          categories: data.data.map((item) => {
            return `${item.customer_number}  ${item.customer_name}`;
          }),
        },
      };
      this.customersChart[chartIndex].info = info;
    },
    async getCustomerSalesChart(params, section) {
      const {data} = await this.historicSales(`${params}`);
      let chartIndex = this.customersChart.findIndex(item => item.mode === 'SALES')
      if (chartIndex < 0) {
        return false
      }
      this.customerSalesData = data.data
      const info = {swe_info: data.swe_info, eng_info: data.eng_info};
      this.customersChart[chartIndex].xaxisLabel = 'Months'
      let currentTabs = this.customersChart[chartIndex].tabs;
      let activeTab = currentTabs.filter(item => item.active === true)
      let tab = activeTab.length > 0 ? activeTab[0].salesAmount : 'total_invoices'
      this.switchSales(section, tab)
      this.customersChart[chartIndex].info = info;
    },
    switchSales(section, value) {
      let cData = {}
      let salesSeries = []
      let dSeries = {}
      let chartIndex = this.customersChart.findIndex(item => item.mode === 'SALES')
      if (typeof this.customerSalesData === 'string') {
        this.customersChart[chartIndex].options = {
          xaxis: {
            categories: [],
          },
        };
        this.customersChart[chartIndex].series = [
          {
            name: this.$t(this.customersChart[chartIndex].chartLabel),
            data: salesSeries,
          },
        ];
        return;
      }
      if (section === 'customer') {
        if ('customer_number' in this.customerSalesData) {
          dSeries = this.customerSalesData['customer_number'][this.customerData.customer.customer_number]
        }
        this.customersChart[chartIndex].options = {
          xaxis: {
            categories: Object.keys(dSeries),
          },
        };
      } else {
        if ('customer_segment' in this.customerSalesData) {
          dSeries = this.customerSalesData['customer_segment'][this.customerData.segment.id]
        }
        this.customersChart[chartIndex].options = {
          xaxis: {
            categories: Object.keys(dSeries),
          },
        };
      }
      Object.keys(dSeries).map(item => {
        cData[item] = dSeries[item]
        let total = dSeries[item].hasOwnProperty(value) === true ? dSeries[item][value] : 0
        salesSeries.push(total)
      })
      this.customersChart[chartIndex].series = [
        {
          name: this.$t(this.customersChart[chartIndex].chartLabel),
          data: salesSeries,
        },
      ];

    },
    async getBenefit(index) {
      // const chartIndex = index;
      let chartIndex = this.customersChart.findIndex(item => item.mode === 'BENEFIT')
      const {data} = await this.getBenefitCost(chartIndex);
      const arrayData = data.data;
      const info = {swe_info: data.swe_info, eng_info: data.eng_info};

      const chartOption = this.customersChart[chartIndex].chooseOption.value;

      Object.keys(arrayData).forEach((item) => {
        this.customersChart[chartIndex][item] = arrayData[item];
      });

      let currentData = this.customersChart[chartIndex][chartOption];
      this.customerBenefitData = currentData
      this.customersChart[chartIndex]["lookup"] = "benefit_cost_ratio";
      this.customersChart[chartIndex].series = [
        {
          name: this.$t(this.customersChart[chartIndex].chartLabel),
          data: currentData.map((item) => {
            return item.benefit_cost_ratio;
          }),
        },
      ];

      this.customersChart[chartIndex].options = {
        xaxis: {
          categories: currentData.map((item) => {
            return `${item.customer_number} `;
          }),
        },
      };
      this.customersChart[chartIndex].info = info;
    },

    async getLatePay() {
      let chartIndex = this.customersChart.findIndex(item => item.mode === 'LATE_PAYING_CUSTOMER')

      const {data} = await this.getHistoryCallLatePaying(chartIndex);
      this.latePayingData = data.data
      const info = {swe_info: data.swe_info, eng_info: data.eng_info};
      this.customersChart[chartIndex].info = info;
      this.switchLatePayingCustomer()

    },
    switchAverageLatePayingCustomer(value) {
      let chartIndex = this.customersChart.findIndex(item => item.mode === 'LATE')
      const vatActive = this.customersChart[chartIndex].tabs.find(
          (tab) => tab.active === true
      );
      if (this.averageLatePaying.length <= 0) {
        return false
      }
      this.customersChart[chartIndex].series = [
        {
          name: this.$t(this.customersChart[chartIndex].chartLabel),
          data: this.averageLatePaying.map((item) => {
            return item[vatActive.monthlyVat];
          }),
        },
      ];

      this.customersChart[chartIndex].options = {
        xaxis: {
          categories: this.averageLatePaying.map((item) => {
            return `${item.customer_number}  ${item.customer_name}`;
          }),
        },
      };

    },
    switchLatePayingCustomer() {
      let xCustomerSeries = []
      let ySeries = []
      let chartIndex = this.customersChart.findIndex(item => item.mode === 'LATE_PAYING_CUSTOMER')
      const chartOption = this.customersChart[chartIndex].chooseOption.value;
      const vatActive = this.customersChart[chartIndex].tabs.find(
          (tab) => tab.active === true
      );
      let targetData = this.latePayingData[chartOption]
      targetData.map((item) => {
        xCustomerSeries.push(item.customer_number)
        ySeries.push(item[vatActive.moneyPay])
      });
      this.customersChart[chartIndex].series = [
        {
          name: this.$t(this.customersChart[chartIndex].chartLabel),
          data: ySeries,
        },
      ];

      this.customersChart[chartIndex].options = {
        xaxis: {
          categories: xCustomerSeries,
        },
      };

    },

    init() {
      this.getBillingCustomers(0);
      this.getAverageLate();
      // this.getSalesCustomer(1);
      this.getBenefit();
      this.getLatePay();
      //this.getHistoryCallLatePaying(5);
    },
    runDynamicChart() {
      this.customersChart = [
        ...this.customersChart.sort((a, b) => a.favorite < b.favorite),
      ];
      this.customersChart.map((el, index) => {
        if (el.mode === "BILLED") {
          this.getBillingCustomers(index);
        }
        if (el.mode === "BENEFIT") {
          this.getBenefit(index);
        }

        if (el.mode === "LATE_PAYING_CUSTOMER") {
          this.getLatePay();
        }

        if (el.mode === "LATE") {
          this.getAverageLate(index);
        }


      });
    },
    searchCustomers(search, loading) {
      if (search.length) {
        loading(true);
        this.searchRemoteCustomers(loading, search, this);

      }
    },
    searchRemoteCustomers: debounce(function (loading, search, vm) {
      this.$useJwt.searchCustomers(`?search=${escape(search)}`)
          .then((res) => {
            this.customerList = res.data.data;
            loading(false);
          })
          .catch((err) => {
          });
    }, 350),
    setCustomer(item) {
      let params = `?customer_number=${item.customer_number}`
      this.getCustomerSalesChart(params, 'customer')
    },
    // searchSegment(search, loading) {
    //   if (search.length) {
    //     loading(true);
    //     this.searchCustomerSegment(loading, search, this);

    //   }
    // },
  //   searchCustomerSegment: debounce(function (loading, search, vm) {
  //     this.$useJwt
  //         .customSegment(
  //             {URL: `/customers?search=${escape(search)}`, method: "get"}
  //         )
  //         .then((res) => {
  //           this.segmentList = res.data.results
  //           loading(false);
  //         });
  //   }, 350),
  //   setSegment(item) {
  //     let params = `?segment_id=${item.id}`
  //     this.getCustomerSalesChart(params, 'segment')
  //   }
  },
  mounted() {
    // this.init();
    this.runDynamicChart();
  },
};
</script>

<style scoped>
.left35 {
  left: 35px;
}

.sales__input {
  position: absolute;
  right: -358px;
}

.select-width {
  width: 132px;
}

.chartTitle2 {
  margin-top: 20px;
}
</style>
